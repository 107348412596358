import apiRequest from "../api/apiRequest";
import { getWeblocationPathGroupWithDynamicConfig } from "./share";
import { apiCaching } from "../config/cachingConfig";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";

export async function getDynamicMenuResponse() {
    let dynamicMenuURLResponse: any = {};
    const dynamic_url =getWeblocationPathGroupWithDynamicConfig();
    try{
        const cachedResponse = await getCachedAPIResponse(dynamic_url, apiCaching.DynamicMenu, false);
        if (cachedResponse) {
            if(typeof cachedResponse === 'object'){
                dynamicMenuURLResponse = cachedResponse;
                localStorage.setItem('dynamicMenuURLResponse', JSON.stringify(dynamicMenuURLResponse));
                setFilterService(cachedResponse);
            }
            return dynamicMenuURLResponse;
        }
    }
    catch(error){
        console.error('Error in fetching state API data:', error);
        throw error;
    }
}

function setFilterService(response:any){

    const data = response.serviceMenu;
    if(data["country"]){
        let options = data["country"][0]?.brand[0]?.option;
        if(localStorage.getItem("dbaName")){
           options = data["country"][0]?.brand[0]?.webLocation?.[0]?.option;
        }
        const brandMenu = options;
        sessionStorage.setItem('filterService',JSON.stringify(brandMenu));
    }
}
