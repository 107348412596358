import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { apiCaching } from "../config/cachingConfig";
import {getCachedAPIResponse} from "../apiCaching/apiWrapper";

let page = 1;
let isLoading = false;
export class Testimonial {
  private data: any;
  private pageNumber: number;
  private pageSize: number;
  private conceptId: any;
  private countryId: any;
  private wrapperElement: any;

  constructor() {
    this.pageNumber = 1; // Initial page number
    this.pageSize = window.innerWidth >= 768 ? 30 : 4; // Number of items per page
    this.conceptId = (document.getElementById("conceptId") as HTMLInputElement)?.value;
    if(sessionStorage.getItem('countryCode') != null){
      this.countryId = sessionStorage.getItem('countryCode')?.toLowerCase()=="us" ? "1" : "2";
    }else{
        this.countryId = (document.getElementById("countryCode") as HTMLInputElement)?.value.toLowerCase() == "us" ? "1" : "2";
    }
    
    document.addEventListener('DOMContentLoaded', () => {
    
    const pageType = (document.getElementById('pageType') as HTMLInputElement)?.value; 

    if(pageType && pageType.toLocaleLowerCase() !=="opus 2 location map"){
      const testimonialDOMisPresent = document.querySelector(".testimonials-section")
      if(testimonialDOMisPresent)
        this.getData();
    }

    this.wrapperElement = document.querySelector('.testimonials-section .container');
        let btn = `<div class="testi-btn-wrapper">                
                 <a href="#" class="primary-btn"> See More...</a>                
                 </div>`
       this.wrapperElement?.insertAdjacentHTML('beforeend', btn);

    const seeMoreButton = document.querySelector('.testi-btn-wrapper .primary-btn');
    if (seeMoreButton) {
      seeMoreButton.addEventListener('click', this.seeMoreData.bind(this));
    }
  });
  }

  private async getData() {
    const weblocationId:any = sessionStorage.getItem('franchiseWebLocationId') ? sessionStorage.getItem('franchiseWebLocationId') : ((document.getElementById('weblocationId') as any)?.value ? (document.getElementById('weblocationId') as any)?.value : '');
    const url = apiConfig.TESTIMONIAL_REVIEWS + `?apikey=${process.env.JS_API_KEY}&ConceptId=${this.conceptId}&CountryId=${this.countryId}&MinimumRating=4&PageNumber=${this.pageNumber}&PageSize=${this.pageSize}&FranchiseWebLocationId=${weblocationId}`
    //calling the Testimonial API from the apiWrapper.ts file
    
    try{   
      let result = await getCachedAPIResponse(url, apiCaching.TestimonyAndReview);
      const seeMoreButton = document.querySelector('.testi-btn-wrapper') as HTMLElement;
      if(seeMoreButton){
        if(result.length ==0 || result.length < this.pageSize){
          seeMoreButton.style["display"] = "none";
          isLoading = false;
        }
        else
        {
          seeMoreButton.style["display"] = "flex";
          isLoading = true
        }  
      }
      const lists = result.map((item: any) => {
        return this.createTestimonialHTML(item);
      })
      const testimonialsList = document.querySelector('.testimonials-section .container .testimonial-wrapper');
      if (testimonialsList) {
        testimonialsList.innerHTML = lists.join('')
      }

    }
    catch{
        this.wrapperElement?.classList.add('hidden');
        isLoading = false
    }
  }
  
  public generateRatingStars(rating: number): string {
    const fullStar = '<span class="star full">★</span>';
    const emptyStar = '<span class="star empty">☆</span>';
    const numberOfFullStars = Math.floor(rating);
    const hasHalfStar = rating % 1 !== 0;
    const numberOfEmptyStars = hasHalfStar ? 5 - numberOfFullStars - 1 : 5 - numberOfFullStars;
    return fullStar.repeat(numberOfFullStars) + (hasHalfStar ? '<span class="star half">★</span>' : '') + emptyStar.repeat(numberOfEmptyStars);
  }
  public createTestimonialHTML(data: any): string {
    return `
        <li class="testi-main-wrap">
          <div class="testi-main">
            <div class="testi-details">
              <div class="testi-profile">
                <img src="${data.reviewPhotoUrl}" alt="profile photo" width="72" height="73">
              </div>
              <div class="testi-bio">
                <strong class="user-name">${data.reviewAuthorName}</strong>
                ${data.city ? `<address class="address">${data.city}</address>` : ''}
                <div class="rating">
                  ${this.generateRatingStars(data.reviewStarRating)}
                </div>
                ${data.reviewSourceType ?? "" ? `<span class="review-source">via ${data.reviewSourceType}</span>` : ""}
              </div>
            </div>
            <div class="testi-comment">
            ${data.reviewBody ? `<p class="body-text-sm">“${data.reviewBody}”</p>` : ''}
             ${data.reviewResponseBody ? `<div class="testi-reply">
                <p class="body-text-sm">Owner Response:</p>
                <p class="body-text-sm">“${data.reviewResponseBody}”</p>
              </div>` : ''}
            </div>
          </div>
        </li>
      `;
  }
  private seeMoreData(event: any) {
    event.preventDefault();
    this.pageNumber++; // Increment the page number for the next page of data
    const request = {
      method: 'GET',
      url: apiConfig.TESTIMONIAL_REVIEWS + `?apikey=${process.env.JS_API_KEY}&ConceptId=${this.conceptId}&CountryId=${this.countryId}&MinimumRating=4&PageNumber=${this.pageNumber}&PageSize=${this.pageSize}&FranchiseWebLocationId=${sessionStorage.getItem('franchiseWebLocationId') ?? ''}`
    };
    apiRequest(request)
      .then((result: any) => {
        const lists = result.map((item: any) => {
          return this.createTestimonialHTML(item);
        });

        const testimonialsList = document.querySelector('.testimonials-section .container .testimonial-wrapper');
        if (testimonialsList) {
          testimonialsList.innerHTML += lists.join('');
        }
        const seeMoreButton = document.querySelector('.testi-btn-wrapper') as HTMLElement;
        if(!result || result.length ==0 || result.length < this.pageSize){
          seeMoreButton.style["display"] = "none";
        }
        else{
          seeMoreButton.style["display"] = "flex";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }
}
const testimonial = new Testimonial();
