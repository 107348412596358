let sConceptCode: any = "MRA";

const conceptCodeElement = document.querySelector('#conceptCode') as HTMLInputElement;
if (conceptCodeElement?.value?.length) {
  sConceptCode = conceptCodeElement.value;
}

let sConceptID: any = "";

const conceptIdElement = document.querySelector('#conceptId') as HTMLInputElement;
if (conceptIdElement?.value?.length) {
  sConceptID = conceptIdElement.value;
}

let sweblocationId: any = "8628";

const weblocationIdElement = document.querySelector('#weblocationId') as HTMLInputElement;
if (weblocationIdElement && weblocationIdElement.value?.length) {
  sweblocationId = weblocationIdElement.value;
}

const brandCode = document.querySelector('#conceptCode') as HTMLInputElement;
// const envType = ()=>{
//   console.log("ddjddewd", window.location.origin)
// }

export const apiConfig = {
  LocateLocationApiWithRoundRobinFalse: `${process.env.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?apikey=${process.env.JS_API_KEY}&Address=sAddressParamValue&IsRoundRobin=false&RequireAddressOnPartialPostalCode=true&conceptID=${encodeURIComponent(sConceptID)}`,
  LocateLocationApiWithRoundRobinTrue: `${process.env.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?apikey=${process.env.JS_API_KEY}&Address=sAddressParamValue&IsRoundRobin=true&RequireAddressOnPartialPostalCode=true&conceptID=${encodeURIComponent(sConceptID)}`,
  APP_LINK_SEND: `${process.env.JS_API_URL}/${process.env.JS_NOTIFICATION_API_SERVICE_NAME}/v1/public/PushNotification/MobileAppPromo?apikey=${process.env.JS_API_KEY}` ,
  FOOTER_BRAND_LINK: `${process.env.JS_API_URL}/${process.env.JS_DigitalOrchestrations_URL}/v1/public/fullattribute/franchiseWeblocations/search/`,
  stateApi: `${process.env.JS_WEB_URL}/${process.env.JS_PROFILE_API_SERVICE_NAME}/v1/public/IdentityExperience/States/`,
  CONTACT_US_SEND:`${process.env.JS_API_URL}/unifiedsyncplatform-api/v1/public/UnifiedSyncPlatform/ContactUs?apikey=${process.env.JS_API_KEY}`,
  BOOKING_API_URL:`${process.env.JS_API_URL}/unifiedsyncplatform-api/v1/public/UnifiedSyncPlatform/Booking?apikey=${process.env.JS_API_KEY}`,
  TESTIMONIAL_REVIEWS:`${process.env.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/Reviews/review-aggregates/search`,
  ourTeam:`${process.env.JS_API_URL}/${process.env.JS_EmployeeOrchestrations_URL}/v1/public/MeetTheTeam/FranchiseWebLocationId/`,
  GETHeaderMenu:`${process.env.JS_API_URL}/${process.env.JS_ServiceOrchestrations_URL}/v1/public/service/config/menu/brand/${brandCode?.value}?apikey=${process.env.JS_API_KEY}`,
  fullAttribute:`${process.env.JS_API_URL}/${process.env.JS_DigitalOrchestrations_URL}/v1/public/fullattribute/franchiseWeblocations/search/`,
  howToKnow:`${process.env.JS_API_URL}/${process.env.JS_ServiceOrchestrations_URL}/v1/public/generic-lead-sources`,
  franchiseWebLocationPostalCodeFees:`${process.env.JS_API_URL}/${process.env.JS_FranchiseGroup_URL}/v1/public/franchiseWebLocationPostalCodeFees`,
  AppointMentAPi:`${process.env.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/TechAvailability`,
  GET_ATTRIBUTE_DATA:`${process.env.JS_API_URL}/${process.env.JS_FranchiseGroup_URL}/v1/public/fullattribute/franchiseWeblocations/search`,
  GET_SERVICE_BUNDLE:`${process.env.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/Settings/GetOnverityServiceBundle`,
  HMS_BOOKING:`${process.env.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/Booking?apikey=${process.env.JS_API_KEY}`,
  BLOG_URL:`https://searchg2.crownpeak.net/`,
  FranchiseLookUpByServiceAddress:`${process.env.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchiseLookUpByServiceAddress?conceptID=${encodeURIComponent(sConceptID)}&apikey=${process.env.JS_API_KEY}`,
  paymentPostUrl: `${process.env.JS_PAYMENT_URL}`,
  POSLookup:`${process.env.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchisePosLookup?conceptid=${encodeURIComponent(sConceptID)}&apikey=${process.env.JS_API_KEY}&postalcode=`,
  syncCleoLeadSources :`${process.env.JS_API_URL}/${process.env.JS_ServiceOrchestrations_URL}/v1/public/concepts/${sConceptID}/lead-sources?apikey=${process.env.JS_API_KEY}`,
  DISCOVER_API:`https://discover.search.hereapi.com/v1/discover`,
  calculateEstimate: `${process.env.JS_UnifiedSyncPlatform_URL}/public/UnifiedSyncPlatform/CalculateEstimate?apikey=${process.env.JS_API_KEY}`,
  FranchisePOSLookup:`${process.env.JS_UnifiedSyncPlatform_URL}/UnifiedSyncPlatform/FranchisePosLookup?apikey=${process.env.JS_API_KEY}&FranchiseWebLocationId=`,
  WebLocationsAPI: `${process.env.JS_API_URL}/${process.env.JS_FranchiseGroup_URL}/v1/public/concepts/${sConceptCode}/franchiseweblocations?apikey=${process.env.JS_API_KEY}`,
  geoCodeSearchApiUrl: 'https://geocode.search.hereapi.com/v1/geocode',
  GenericLeadSourceEndpoint: `${process.env.JS_API_URL}/${process.env.JS_ServiceOrchestrations_URL}/v1/public/generic-lead-sources?apikey=${process.env.JS_API_KEY}`,
  MollyLeadSourceEndpoint: `${process.env.JS_API_URL}/${process.env.JS_ServiceOrchestrations_URL}/v1/public/concepts/8/lead-sources?apikey=${process.env.JS_API_KEY}`,
};