import moment from "moment";
import { startLoader, stopLoader } from "../util/loader";
import { callBookingApi, checkTechAvailability, configureMollyEstimate } from "./callBookingApi";
import { tns } from "tiny-slider";
import _ from "lodash";

let date: any, time: any, schaduleId: any;
let globalBeforeUnloadHandler: (event: BeforeUnloadEvent) => void;
function removeDuplicateDates(resultCollection: any) {
    let filteredResultCollection: any = [];
    for (const item of resultCollection) {
        const it = {
            "dateId": item.$id as number,
            "isAvailable": item.isAvailable as boolean,
            "startDate": item.startDate.split('T')[0] as string,
            "scheduleID": item.scheduleID as number,
            "displayText": item.displayText as string
        };
        filteredResultCollection.push(it);
    }
    return filteredResultCollection;
}

function convertDate(str: string): string {
    let monthNames = [
        "January", "February", "March",
        "April", "May", "June",
        "July", "August", "September",
        "October", "November", "December"
    ];
    let out: string = '';
    monthNames.forEach((month: string) => {
        if (_.startsWith(month, str)) {
            out = month;
        }
    })
    return out;
}

function setCard(data: any) {
    let uniqueDates = new Set();
    return data.map((item: any) => {
        const filteredData: any = data?.some((x: any) => moment(x.startDate).format('DD MMM, YYYY') == moment(item.startDate).format('DD MMM, YYYY') && x.isAvailable);
        if (!uniqueDates.has(item.startDate)) {
            uniqueDates.add(item.startDate);
            return (`
                <li class="date-details-item">
                <button type="button" class="${!filteredData ? 'date-details-disable' : moment(item.startDate).format('ddd DD MMM, YYYY') === moment(date).format('ddd DD MMM, YYYY') ? 'date-details-inactive date-details-active dateapp' : 'date-details-inactive dateapp'}"
                value=${item.startDate}>
                    <span value=${item.startDate} class="date-text-small">${moment(item.startDate).format('MMM')}</span>
                    <strong value=${item.startDate} class="date-text-normal">${moment(item.startDate).format('DD')}</strong>
                    <input class="getDateApp" hidden value=${item.startDate}>
                    <input class="scheduleID" hidden value=${item.scheduleID}>                                
                    <span value=${item.startDate} class="date-text-small">${moment(item.startDate).format('ddd')} </span>
                </button>
            </li>
            `)
        }

    })
}

function setTinySlider(data: any, currentIndex: any) {
    try {
        tns({
            container: '.mly-date-slider',
            loop: false,
            gutter: 8,
            items: 5,
            slideBy: 'page',
            nav: false,
            autoplay: false,
            speed: 400,
            swipeAngle: false,
            autoplayButtonOutput: false,
            mouseDrag: true,
            lazyload: true,
            startIndex: currentIndex,
            controlsContainer: "#customize-controls",
            responsive: {
                350: {
                    items: 5,
                },
                640: {
                    items: 5,
                },
                768: {
                    items: 5,
                },
                1024: {
                    items: 5,
                }
            }
        });

    } catch (error) {

    }
}

function handleLeadFlow(bookingPayload: any) {
    bookingPayload.IsLeadOnly = true;
    callBookingApi(bookingPayload);
}

function initCalendarScreen(bookingPayload: any) {
    const elems = document.querySelectorAll('#form-section .form-step');
    elems.forEach((el: any) => {
        if (el?.className.includes('appointment-details-section')) {
            el.classList.add('form-step-active');
        }
        else {
            el.classList.remove('form-step-active');
        }
    });
    let progressBar = document.querySelector("#progress") as HTMLElement;
    progressBar.style.width = "100%";
    let bars = document.querySelectorAll('.progress-step');
    bars[1].classList.add("prograssbar-length");
    bars[1].classList.add("progress-step-active");
    const type = sessionStorage.getItem('JobType')?.toLowerCase();
    const recurheading = document.querySelector('.recurring-estimation-heading') as HTMLElement;
    const otheading = document.querySelector('.one-time-heading-wrap') as HTMLElement;

    const legalText = document.querySelector('.heading-thanyou-text');
   
        if (type?.includes('recurring')) {
            recurheading.style.display = 'flex';
            otheading.style.display = 'none';
        } else {
            recurheading.style.display = 'none';
            otheading.style.display = 'flex';  
        }
        if (legalText && legalText?.textContent?.trim() !== '') {
            globalBeforeUnloadHandler = function handleBeforeUnload(event: BeforeUnloadEvent) {
                handleLeadFlow(bookingPayload);
                event.stopPropagation();
            };
            
            window.addEventListener('beforeunload', globalBeforeUnloadHandler);    
        }
       
    }

    function removeBeforeUnloadListener() {
        if (globalBeforeUnloadHandler) {
            window.removeEventListener('beforeunload', globalBeforeUnloadHandler);
        }
    }
    export function calendarFlowMLY(bookingPayload: any) {

        const div = document.querySelector('.calendar-container');
        if (div) {
            const mcfObj = new MollyCalendarFlow(bookingPayload);
            checkTechAvailability()
                .then((response: any) => {
                    if (response?.resultCollection) {
                        const data = response?.resultCollection;
                        const availability = data.some((dt: any) => {
                            return dt.isAvailable;
                        });
                        if (availability) {
                            initCalendarScreen(bookingPayload);
                            let currentIndex = data.findIndex((dt: any) => dt.isAvailable);
                            date = data?.find((x: any) => x.isAvailable).startDate;
                            time = '';
                            const estFlag = configureMollyEstimate();
                            if (estFlag) {
                                displayEstimate();
                            }
                            else {
                                bookingPayload.Note = bookingPayload.Note.concat("No Calendar Pricing");
                                console.log('CES configured to NOT show estimate');
                            }
                            let scId = data[currentIndex]?.scheduleID;
                            const filterData = removeDuplicateDates(data);
                            const cards = setCard(filterData);
                            if (mcfObj.dateSlider) {
                                mcfObj.dateSlider.innerHTML = '';
                                mcfObj.dateSlider.innerHTML = cards.join('');
                            }
                            mcfObj.selectedDateTime.innerHTML = `${date ? moment(date).format('ddd MMM DD') : "Please Select a Date"}, <span class="select-time"> Please Select a Time </span>`;
                            const wrapperDiv = document.querySelector('.calendar-container .date-details-wrapper') as HTMLElement;
                            wrapperDiv.style.display = "flex";
                            mcfObj.addListner(data, scId);
                            const startIndex = Math.floor(currentIndex / 13);
                            setTinySlider(data, startIndex);
                            const itemFInd = data[currentIndex];
                            mcfObj.setTimeUl(data, itemFInd);
                            stopLoader();
                        }
                        else {
                            console.log("Tech Availability API has no available slots => Submitting Lead flow ");
                            removeBeforeUnloadListener();
                            handleLeadFlow(bookingPayload);
                        }

                    }
                    else {
                        console.log("Tech Availability API returns empty => Submitting Lead flow ");
                        removeBeforeUnloadListener();
                        handleLeadFlow(bookingPayload);
                    }

                })
                .catch((error) => {
                    console.error("Tech Availability API fail=> Submitting lead flow:  ", error);
                    removeBeforeUnloadListener();
                    handleLeadFlow(bookingPayload);
                });
        }
        else {
            console.error("Error in HTML");
        }

    }
   
    function displayEstimate() {

        try {
            const estimate = sessionStorage.getItem('mollyEstimate');
            const estimateData = JSON.parse(estimate as string);
            const estimateContainer = document.querySelector('.one-time-amount') as HTMLElement;
            const frequencyCleaningOne = (document.getElementById('frequency-cleaning-one') as HTMLInputElement)?.checked;
            const oneTimeClean = (document.getElementById('ont-time-clean') as HTMLInputElement)?.checked;
            const moveOutClean = (document.getElementById('move-out-clean') as HTMLInputElement)?.checked;
            const dateDay = getDayOfWeek(date);
            if (frequencyCleaningOne) {
                if (estimateData && oneTimeClean) {
                    estimateContainer.innerHTML = `$${estimateData?.estimateOccasionalRangeLow} - $${estimateData?.estimateOccasionalRangeHigh}`;
                } else if (estimateData && moveOutClean) {
                    estimateContainer.innerHTML = `$${estimateData?.estimateMoveRangeLow} - $${estimateData?.estimateMoveRangeHigh}`;
                } else if (dateDay === 'Saturday' && oneTimeClean && estimateData) {
                    estimateContainer.innerHTML = `$${estimateData?.estimateOccasionalRangeLowSaturday} - $${estimateData?.estimateOccasionalRangeHighSaturday}`;
                    // Remove the redundant condition
                    estimateContainer.innerHTML = `$${estimateData?.estimateMoveRangeLowSaturday} - $${estimateData?.estimateMoveRangeHighSaturday}`;
                }
            }
        } catch (error) {
            console.log('Error in displaying estimate', error);
        }

    }

    function getDayOfWeek(dateString: any) {
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const date = new Date(dateString);
        return daysOfWeek[date.getDay()];
    }

    export class MollyCalendarFlow {
        public dateSlider: HTMLElement;
        private availableHrs: HTMLElement;
        public selectedDateTime: HTMLElement;
        private requestCallBack: HTMLInputElement;
        private backBtn: HTMLButtonElement;
        private confirmBtn: HTMLButtonElement;
        private bookingPayload: any;
        private containerClass: HTMLElement;
        private payload: any;
        constructor(payload: any) {
            this.dateSlider = document.querySelector('.date-details-wrapper #start-index') as HTMLElement;
            this.availableHrs = this.getCalendarRow();
            this.availableHrs.innerHTML = '';
            this.selectedDateTime = document.querySelector('.appointment-date-wrap .appointment-section-text') as HTMLElement;
            this.requestCallBack = document.querySelector('.sheduling-time-box #link-checkbox') as HTMLInputElement;
            this.backBtn = document.querySelector('.btn-container .btn-cancel') as HTMLButtonElement;
            this.confirmBtn = document.querySelector('.btn-container .primary-btn') as HTMLButtonElement;
            this.containerClass = document.querySelector('.calendar-container') as HTMLElement;
            this.bookingPayload = payload;
            this.initListeners();
        }

        initListeners() {
            this.backBtn.addEventListener('click', (e: any) => {
                e.preventDefault();
                const elems = document.querySelectorAll('#form-section .form-step');
                elems.forEach((el: any) => {
                    if (el?.className.includes('appointment-details-section')) {
                        el.classList.remove('form-step-active');
                    }
                    else {
                        el.classList.add('form-step-active');
                    }
                });
                let progressBar = document.querySelector("#progress") as HTMLElement;
                progressBar.style.width = "0%";
                let bars = document.querySelectorAll('.progress-step');
                bars[1].classList.remove("prograssbar-length");
                bars[1].classList.remove("progress-step-active");
            });

            this.confirmBtn.addEventListener('click', (e: any) => {
                e.preventDefault();
                console.log('Call booking API');
                if (this.requestCallBack.checked) {
                    // Request callback flow
                    this.bookingPayload.IsLeadOnly = true;
                }
                if (this.validateForm()) {
                    if (!this.requestCallBack.checked) {
                        this.storeDateTime();
                    }
                    this.bookingPayload.ScheduleID = schaduleId;
                    removeBeforeUnloadListener();
                    callBookingApi(this.bookingPayload);
                }
            });
            this.requestCallBack.addEventListener('click', this.handleDisableApp.bind(this))
        }

        hideDateTimeError() {
            const dataTimeErrorModal = document.getElementById('mly-appointment-date-time-error-msg') as HTMLElement;
            if (dataTimeErrorModal) {
                dataTimeErrorModal.classList.add('hidden');
            }
            const requestCallbackErrorModal = document.getElementById('mly-link-checkbox-error-msg') as HTMLElement;
            if (requestCallbackErrorModal) {
                requestCallbackErrorModal.classList.add('hidden');
            }
        }

        private validateForm(): boolean {
            const dateTime = document.getElementById('mly-appointment-date-time-error-msg') as HTMLElement;
            const requestCallbackCheckbox = document.getElementById('mly-link-checkbox-error-msg') as HTMLElement;

            const linkCheckbox: HTMLInputElement | null = document.getElementById('link-checkbox') as HTMLInputElement | null;

            if ((date && time) || linkCheckbox?.checked) {
                return true;
            } else {
                if (dateTime) {
                    dateTime.classList.remove('hidden');
                    if (!dateTime?.innerText) {
                        dateTime.innerText = "Please select a date and time";
                    }
                }
                if (requestCallbackCheckbox) {
                    requestCallbackCheckbox.classList.remove('hidden');

                    if (!requestCallbackCheckbox?.innerText) {
                        requestCallbackCheckbox.innerText = "or choose to get a call back.";
                    }
                }
                return false;
            }
        }
        handleDisableApp() {
            console.log("Request callback clicked");
            const getContainer: any = document.querySelector('.calendar-container');
            if (getContainer) {
                getContainer.classList.toggle('disable-calendar');
            }
            const callbackwrapper = document.querySelector('.callback-wrapper');
            if (callbackwrapper) {
                callbackwrapper?.classList.toggle('callback-requested');
            }

            this.hideDateTimeError();

        }
        getCalendarRow() {
            let rows: any;
            const type = sessionStorage.getItem('JobType')?.toLowerCase();
            if (type?.includes('recurring')) {
                rows = document.querySelector('.recurring-calendar');
            }
            else {
                rows = document.querySelector('.one-time-calendar');
            }
            return rows;
        }
        storeDateTime() {
            const parts = this.selectedDateTime.innerText.split(',');
            let selectedDate: any = parts[0];
            selectedDate = selectedDate.split(' ');
            const year = new Date().getFullYear();
            const text1 = selectedDate[0] + `day, ` + convertDate(selectedDate[1]) + ` ` + selectedDate[2] + `, ${year}`;
            const text2 = getTime(parts[1]);
            sessionStorage.setItem('BookingDate', text1);
            sessionStorage.setItem('BookingTime', text2);
            sessionStorage.setItem('userSelectDatae', `${date}, ${time}`);
        }
        setTimeUl(allAppData: any, item: any) {
            const calendarRow = this.getCalendarRow();
            const sampleData: any = allAppData?.filter((x: any) => moment(x.startDate).format('DD MMM, YYYY') == moment(item?.startDate).format('DD MMM, YYYY'))
            const ulTimeData = sampleData?.map((x: any, i: any) => {
                return (`<li class="calendar-row-item"><button type="button" value="${x.scheduleID}" class="calendar-col btn-time  ${x.isAvailable == false ? 'disable-btn' : ''} "  id="${x.displayText}">${x.displayText}</button></li>`)
            })
            if (calendarRow) {
                calendarRow.innerHTML = ulTimeData.join('');
            }
            this.setListnerOnUlBtn();
            this.setAppointmentTimeText(sampleData);
        }

        addListner(allAppData: any, scId: any) {
            document.querySelectorAll('.dateapp')?.forEach((item: any) => {
                item.addEventListener('click', (e: any) => {
                    const rm = document.querySelectorAll('.dateapp')
                    rm.forEach(e => e.classList.remove('date-details-active'));
                    date = moment(item.querySelector('.getDateApp').value).format('ddd DD MMM, YYYY');
                    displayEstimate();
                    time = '';
                    item.classList.toggle('date-details-active');
                    schaduleId = item.querySelector('.scheduleID').value;
                    const itemFInd = allAppData?.find((x: any) => x.scheduleID == schaduleId)
                    document.querySelector('.appointment-section-text')!.innerHTML = `${moment(item.querySelector('.getDateApp').value).format('ddd MMM DD')}, <span class="select-time"> Please Select a Time </span>`;
                    this.setTimeUl(allAppData, itemFInd);
                })
            })
        }

        setListnerOnUlBtn() {
            const timesBtn = document.querySelectorAll('.calendar-col');
            timesBtn.forEach(e => e.classList.remove('active'));
            let self = this;
            if (timesBtn) {
                timesBtn.forEach((item: any) => {
                    item.addEventListener('click', (e: any) => {
                        timesBtn.forEach(e => e.classList.remove('active'));
                        item.classList.toggle('active');
                        const timecontainer = document.querySelector('.select-time')
                        if (timecontainer)
                            timecontainer.innerHTML = item.innerHTML
                        schaduleId = item.value;
                        time = item.id;
                        self.hideDateTimeError();
                    })

                })
            }
        }

        setAppointmentTimeText(data: any) {
            const timeButtons = document.querySelectorAll('.calendar-col.btn-time');
            timeButtons.forEach((item) => {
                data.forEach((element: any) => {
                    if (item.innerHTML == element.displayText) {
                        item.setAttribute("value", element.scheduleID);
                        if (!element.isAvailable)
                            item.setAttribute("disabled", "");
                    }
                });

            })
        }
    }

    function getTime(arg: string): string {
        const timeString = arg.split(' - ')
        const parsedTime = moment(timeString, ['h:mm A', 'hh:mm A']);
        const formattedTime = Number(parsedTime.format('HH'));
        if (formattedTime <= 12) {
            return 'Morning';
        }
        else if (formattedTime > 12 && formattedTime <= 17) {
            return 'Afternoon';
        }
        else {
            return 'Evening'
        }

    }

