import { getBrandJSON } from "../util/locationBasedBrandJson";
import apiRequest from "../api/apiRequest";
export class GetHoursOfOperation {
    constructor(){
        this.toggleHoursOfOperation();
    }
    
    private setHoursOfOperation() {
        const ulElement = document.querySelector('.get_in_touch_hrs_operation .card-item') as HTMLUListElement;
        const fullAttrOnLoad = localStorage.getItem("fullAttrOnLoad");
        
        //checking if the said entry exists in the local storage
        if (fullAttrOnLoad && JSON.parse(fullAttrOnLoad).data.hoursofOperation) {
            const data = JSON.parse(fullAttrOnLoad).data;
            const DaysOfWeek: { [key: number]: string } = {
                0: 'Sunday',
                1: 'Monday',
                2: 'Tuesday',
                3: 'Wednesday',
                4: 'Thursday',
                5: 'Friday',
                6: 'Saturday'
            };

            //get the current date of the week in numerical
            const curDay = new Date().getDay();

            //sorting so that the result may always be in acending order in terms of days of week
            const hoursofOperation = data.hoursofOperation.sort((a: { dayOfWeek: number; }, b: { dayOfWeek: number; }) => (a.dayOfWeek > b.dayOfWeek) ? 1 : -1);

            //This would ensure that the current day is the first item in the week array.
            hoursofOperation.splice(0,0, ...hoursofOperation.slice(curDay));
            hoursofOperation.splice(7,(7-curDay));
            const hoursofOperationbyCurDay: any[] = hoursofOperation

            //This is the setting of time format.
            hoursofOperationbyCurDay.forEach((item, index: number) => {
                let NormalHour = "";
                if (!item.isOpenForNormalHours) {
                    NormalHour = "Closed";
                } else {
                    //section to convert minutes to hours
                    let curStartHour: any = Math.floor(parseInt(item.startMinutes) / 60);
                    let curStartMinute: any = Math.floor(parseInt(item.startMinutes) % 60).toString();
                    let curEndHour: any = Math.floor(parseInt(item.endMinutes) / 60);
                    let curEndMinute: any = Math.floor(parseInt(item.endMinutes) % 60).toString();

                    //Setting the AM and PM based on hour value
                    const curStartHour_suffix = (curStartHour >= 12) ? " PM" : " AM";
                    const curEndHour_suffix = (curEndHour >= 12) ? " PM" : " AM";

                    //Prefixing 0 if the value of minute is a single digit
                    curStartMinute = curStartMinute.length == 1 ? "0" + curStartMinute : curStartMinute;
                    curEndMinute = curEndMinute.length == 1 ? "0" + curEndMinute : curEndMinute;

                    //Converting the 24 hour format to a 12 hour format
                    curStartHour = ((curStartHour + 11) % 12 + 1) + ":" + curStartMinute + curStartHour_suffix;
                    curEndHour = ((curEndHour + 11) % 12 + 1) + ":" + curEndMinute + curEndHour_suffix;

                    //Final time format in hours and minutes (in a 12 hour format)
                    NormalHour = curStartHour + " - " + curEndHour;
                }
                const formattedHours = `${DaysOfWeek[item.dayOfWeek]} ${NormalHour}`;

                const liElement = document.createElement('li');
                
                //index comes from the item in the forEach loop. Checks for first item in li and sets a class unique to the first item
                liElement.className = (index === 0)?'card-text':'card-listtext';
                liElement.textContent = formattedHours;
                ulElement.appendChild(liElement);
            });
        }
        
    }

    public getHoursOfOperation() {
        try {
            const ulElement = document.querySelector('.get_in_touch_hrs_operation .card-item') as HTMLUListElement;
            const items = document.querySelectorAll(".card-item li");
            //Checking if the Hours are already authored by CP
            if (ulElement && ulElement.children.length<7) {
                ulElement.innerHTML = '';
                this.setHoursOfOperation();
            }
            else if(ulElement && ulElement.children.length===7 && items){
                let authoredArray:any = []
                items.forEach((element)=>authoredArray.push(element.textContent))

                //adding -1 as CP is starting the week from Monday instead of Sunday
                const curDay = (new Date().getDay())-1;
                authoredArray.splice(0,0, ...authoredArray.slice(curDay));
                authoredArray.splice(7,(7-curDay));

                ulElement.innerHTML = '';
                authoredArray.forEach((element:any,index:number)=>{
                    const liElement = document.createElement('li');
                    liElement.className = (index === 0)?'card-text':'card-listtext';
                    liElement.textContent = element;
                    ulElement.appendChild(liElement); 
                })
            }
            
        } catch (error) {
            console.log(error);
        }
    }

    async toggleHoursOfOperation(){
        const brandJSON = localStorage.getItem("brandDetails");
        if(brandJSON){
            const brandDetails = JSON.parse(brandJSON);
            if(brandDetails.disableHoursOfOperation){
                this.hideHoursOfOperation();
                return
            }
            this.getHoursOfOperation();
        }else{
            const conceptCodeElement = document.getElementById('conceptCode') as HTMLInputElement;
            if (conceptCodeElement) {
                const conceptCode = conceptCodeElement.value;
                try {
                    const brandJson = getBrandJSON();
                    if(brandJson === 'Error validating Location'){
                        console.error("Error Determining BrandJSON path for given location");
                        return;
                    }
                    else{
                        const origin = window.location.origin;
                        const request = {
                            method: 'GET',
                            url: `${origin}/${brandJson}`
                        };
            
                        const result :any = await apiRequest(request);
                        const brandDetails = result.find((item: any) => item.code === conceptCode);
            
                        localStorage.setItem("brandDetails", JSON.stringify(brandDetails));
                        if(brandDetails.disableHoursOfOperation){
                            this.hideHoursOfOperation();
                            return
                        }
                        this.getHoursOfOperation();
                    }
                } catch (error) {
                    console.error('Error in fetching getBrandDetailsFromBrandJson API data:', error);
                    return [];
                }
            }
        }
    }

    hideHoursOfOperation(){
        const contactElement = document.querySelector('.contact-info .contact-block .get_in_touch_hrs_operation') as HTMLLIElement;
        if(contactElement)
            contactElement.style.display = 'none';
    }
}
new GetHoursOfOperation();