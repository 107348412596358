import apiRequest from "../../api/apiRequest";
import { apiConfig } from "../../config/apiConfig";
import { REGEX } from "../../constants/regex";
import { ScreenType } from "../../location-search-map/models/ScreenType";
import { reportScreenSize } from "../../location-search-map/reportScreenSize";
import { hmsProgressBarUpdate, removeAllEventListeners } from "../../util/share";
import { DonorConfirmation } from "./donor-confirmation";
interface CustomSelectElement extends HTMLDivElement {
    selectButton: HTMLButtonElement;
}
declare global {
    interface Window {
        donorPayload?: Record<string, any>,
        shelterList?: Record<string, any>
    }
    interface Shelter {
        $id: string;
        MsMollyShelterId: number;
        Name: string;
        City: string;
        State: string;
        Zip: string;
        IsActive: boolean;
        DeletedDateTime: null | string;
    }
    
    interface StateData {
        State: string;
        Data: Shelter[];
    }

    interface StateCodes {
        [key: string]: string;
    }
}

type ExceptType = HTMLButtonElement | HTMLDivElement | null; // Add other types as needed
export class Donation {

    stateList:StateCodes = {};
    
    constructor() {
        this.init();
    }

    private init(): void {
        try {
            this.addCancelDonationListener();
            this.addSubmitDonationListener();
            this.addIsCurrentDonationListener();
            this.addInputListener();
            (async () => {
                try {
                    await Promise.all([
                        this.getUsState(),
                        this.getShelterList()
                    ]);
                    this.initializeCustomSelect();

                } catch (error) {
                    // Handle any errors that may occur during promise resolution
                    console.error("Error:", error);
                }
            })();

        } catch (error) {
            console.error('An error occurred while adding event listeners:', error);
        }
    }

    public addInputListener(): void {
        const inputFields = document.querySelectorAll('.donation-form input');
        const errors: Record<string, string> = {};

        inputFields?.forEach(input => {
            input?.addEventListener('input', (event) => {
                const target = event.target as HTMLInputElement;
                const inputValue = target.value;

                switch (input.id) {
                    case 'donation-fname':
                        this.validateFirstName(errors, input.id);
                        break;
                    case 'donation-lname':
                        this.validateLastName(errors, input.id);
                        break;
                    case 'yes-donation-amount':
                        target.value = inputValue.replace(/[^\d.]/g, '');
                        this.validateDonationAmountInput(errors, target.value, input.id);
                        break;
                    case 'donation-email':
                        this.validateEmailIput(errors, input.id);
                        break;
                    case 'no-donation-amount':
                        target.value = inputValue.replace(/[^\d.]/g, '');
                        this.validateDonationAmountInput(errors, target.value, input.id);
                        break;
                    case 'donation-address':
                        this.validateAddress(errors, input.id);
                        break;
                    case 'donation-city':
                        this.validateCity(errors, input.id);
                        break;
                    case 'donation-zip-code':
                        this.validateZipCode(errors, input.id);
                }

                const errorElement = document.getElementById(`${input.id}-error-msg`);
                if (errorElement) {
                    errorElement.classList.add('hidden');
                }
                input.classList.remove('invalid-field');
                this.displayErrors(errors);
            });
        });
    }

    private validateZipCode(errors: Record<string, string>, id: any) {
        const input = document.getElementById(id) as HTMLInputElement;
        if (input?.value && !REGEX.sendZip.test(input?.value.trim())) {
            errors[input?.id] = 'Invalid zip code format';
        } else {
            delete errors[id];
        }
    }

    private validateDonationAmountInput(errors: Record<string, string>, amount: string, id: any): void {
        const value = Number(amount);

        if (isNaN(value)) {
            errors[id] = 'Donation amount must be a number.';
        }

        if (value < 5 || value > 2000) {
            errors[id] = 'Donation amount must be between 5 and 2000.';
        } else {
            delete errors[id];
        }
    }

    private validateFirstName(errors: Record<string, string>, id: string): void {
        const inputElement = document.getElementById(id) as HTMLInputElement;
        if (inputElement?.value && !REGEX.sendName.test(inputElement?.value.trim())) {
            errors[id] = 'Invalid first name';
        }
        else {
            delete errors[id];
        }
    }

    private validateLastName(errors: Record<string, string>, id: string): void {
        const inputElement = document.getElementById(id) as HTMLInputElement;
        if (inputElement?.value && !REGEX.sendName.test(inputElement?.value.trim())) {
            errors[id] = 'Invalid last name';
        }
        else {
            delete errors[id];
        }
    }

    private validateEmailIput(errors: Record<string, string>, id: string): void {
        const inputElement = document.getElementById(id) as HTMLInputElement;
        if (!REGEX.sendEmail.test(inputElement.value)) {
            errors[id] = 'Invalid email format';
        } else {
            delete errors[id];
        }
    }

    private validateAddress(errors: Record<string, string>, id: string): void {
        const inputElement = document.getElementById(id) as HTMLInputElement;
        if (inputElement?.value && !REGEX.sendAddress1.test(inputElement?.value?.trim())) {
            errors[id] = 'Please enter valid address';
        } else {
            delete errors[id];
        }
    }

    private validateCity(errors: Record<string, string>, id: string): void {
        const inputElement = document.getElementById(id) as HTMLInputElement;
        if (inputElement?.value && !REGEX.sendName.test(inputElement.value)) {
            errors[id] = 'Please enter valid city';
        } else {
            delete errors[id];
        }
    }

    public addIsCurrentDonationListener(): void {
        const radioButtons = document.getElementsByName('mly-customer-radio');

        radioButtons?.forEach(radio => {
            radio?.addEventListener('click', (e) => this.handleRadioClick(e));
        });

    }

    private handleRadioClick(event: Event): void {
        const radioBtnValue = (event?.target as HTMLInputElement)?.value;
        const noCustumerDom = document.getElementById('mlyCustomerNo') as HTMLElement;
        const yesCustumerDom = document.getElementById('mlyCustomerYes') as HTMLElement;
        if (radioBtnValue === 'no') {

            if (noCustumerDom) {
                noCustumerDom.style.display = 'flex';
            }
            if (yesCustumerDom) {
                yesCustumerDom.style.display = 'none';
            }
            const shelterList = window.shelterList;
            if (shelterList?.length > 0) {
                this.createShelterSelectOption(shelterList);
            }
        } else if (radioBtnValue === 'yes') {
            if (noCustumerDom) {
                noCustumerDom.style.display = 'none';
            }
            if (yesCustumerDom) {
                yesCustumerDom.style.display = 'flex';
            }
        }
    }
    public addCancelDonationListener(): void {
        try {
            const cancelDonationBtn = removeAllEventListeners('.donation-form .cancel-verification') as HTMLElement;
            cancelDonationBtn?.addEventListener('click', this.cancelDonation);
        } catch (error) {
            console.error('An error occurred while adding the cancel donation listener:', error);
        }
    }

    private cancelDonation(): void {
        console.log('cancel button clicked');
        if (document.referrer) {
            window.history.back();
        } else {
            window.location.href = window.location.origin;
        }
    }

    public addSubmitDonationListener(): void {
        try {
            const submitDonationBtn = removeAllEventListeners('.donation-form .btn-next') as HTMLElement;
            submitDonationBtn?.addEventListener('click', () => this.submitDonation());
        } catch (error) {
            console.error('An error occurred while adding the submit donation listener:', error);
        }
    }

    private submitDonation(): void {
        console.log('submit button clicked');
        let IsMobile = false;
        const screenType = reportScreenSize();
        if (screenType !== ScreenType.Desktop) {
            IsMobile = true
        }
        if (this.isValid()) {
            const isMlyCustmerInput = document.querySelector('input[name="mly-customer-radio"]:checked') as HTMLInputElement | null;
            const donorAmtElement = isMlyCustmerInput?.value.toLowerCase() == 'yes' ? document.getElementById('yes-donation-amount') as HTMLInputElement : document.getElementById('no-donation-amount') as HTMLInputElement;
            const donorAmt = donorAmtElement ? Number(donorAmtElement.value) : 0;
            let shelterId = null//(document.getElementById('choose-all-shelter') as HTMLSelectElement)?.value;
            const donorFirstName = (document.getElementById('donation-fname') as HTMLInputElement)?.value;
            const donorLastName = (document.getElementById('donation-lname') as HTMLInputElement)?.value;
            const donorEmail = (document.getElementById('donation-email') as HTMLInputElement)?.value;
            const donorAddress = (document.getElementById('donation-address') as HTMLInputElement)?.value;
            const donorCity = (document.getElementById('donation-city') as HTMLInputElement)?.value;
            const donorState = (document.getElementById('mly-donation-state') as HTMLInputElement)?.value;
            const donorZipCode = (document.getElementById('donation-zip-code') as HTMLInputElement)?.value;
            const isCurrentCustomer = (document.getElementById('mly-customer-yes') as HTMLInputElement)?.checked;
            let comment = ' '

            if (isCurrentCustomer) {
                const yesCustumerChooseShelter = (document.getElementById('yes-customer-choose-shelter') as HTMLSelectElement)?.value;
                if (yesCustumerChooseShelter && yesCustumerChooseShelter == 'yes-stateShelter') {
                    const stateQuestionComment = (document.getElementById('state-questn-comment') as HTMLInputElement)?.value;
                    if (stateQuestionComment) {
                        comment = stateQuestionComment;
                    }
                    const shelterStateId = (document.getElementById('choose-state-shelter') as HTMLSelectElement)?.value;
                    if (shelterStateId) {
                        shelterId = shelterStateId;
                    }
                } else if (yesCustumerChooseShelter && yesCustumerChooseShelter == 'yes-allShelter') {
                    const stallestionComment = (document.getElementById('all-questn-comment') as HTMLInputElement)?.value;
                    if (stallestionComment) {
                        comment = stallestionComment;
                    }
                    const shelterStateId = (document.getElementById('choose-all-shelter') as HTMLSelectElement)?.value;
                    if (shelterStateId) {
                        shelterId = shelterStateId;
                    }
                } else if (yesCustumerChooseShelter && yesCustumerChooseShelter == 'yes-localShelter') {
                  
                    const localComment = (document.getElementById('local-questn-comment') as HTMLInputElement)?.value;
                    if (localComment) {
                        comment = localComment;
                    }
                }
            } else {
                const noCustumerChooseShelter = (document.getElementById('no-customer-choose-shelter') as HTMLSelectElement)?.value;
                if (noCustumerChooseShelter && noCustumerChooseShelter == 'no-stateShelter') {
                    const stateQuestionCommentNo = (document.getElementById('state-questn-comment-no') as HTMLInputElement)?.value;
                    if (stateQuestionCommentNo) {
                        comment = stateQuestionCommentNo;
                    }
                    const shelterStateId = (document.getElementById('choose-state-shelter-no') as HTMLSelectElement)?.value;
                    if (shelterStateId) {
                        shelterId = shelterStateId;
                    }
                } else if (noCustumerChooseShelter && noCustumerChooseShelter == 'no-allShelter') {
                    const allShelterQuestionCommentNo = (document.getElementById('_national_no_options') as HTMLInputElement)?.value;
                    if (allShelterQuestionCommentNo) {
                        comment = allShelterQuestionCommentNo;
                    }
                    const shelterStateId = (document.getElementById('choose-all-shelter-no') as HTMLSelectElement)?.value;
                    if (shelterStateId) {
                        shelterId = shelterStateId;
                    }
                } else if (noCustumerChooseShelter && noCustumerChooseShelter == 'no-localShelter') {
                    const localQuestionCommentNo = (document.getElementById('local-questn-comment-no') as HTMLInputElement)?.value;
                    if (localQuestionCommentNo) {
                        comment = localQuestionCommentNo;
                    }
                }
            }

            const url = window.location.href;
            const urlObj = new URL(url);

            const donorPayload = {
                "Amount": donorAmt,
                "MsMollyShelterId": shelterId ? Number(shelterId) : null,
                "DonorFirstName": donorFirstName,
                "DonorLastName": donorLastName,
                "DonorEmail": donorEmail,
                "DonorAddress": donorAddress,
                "DonorCity": donorCity,
                "DonorState": donorState,
                "DonorPostalCode": donorZipCode.trim(),
                "IsCurrentCustomer": isCurrentCustomer,
                "IsMobile": IsMobile,
                "Campaign": urlObj?.search ?? null,
                "Comments": comment || ''
            };

            window.donorPayload = donorPayload;
            console.log('Form is valid');
            this.switchToNextStep();
            if (window.donorPayload) {
                this.prePurchaseCertificate();
            }
        }
    }

    private isValid(): boolean {
        this.hideErrors();
        const errors: Record<string, string> = {};
        this.validateInput(errors, 'donation-fname', 'First Name is required');
        this.validateInput(errors, 'donation-lname', 'Last Name is required');
        this.validateEmail(errors, 'donation-email', 'Valid Email is required');
        this.validateAddressInput(errors, 'donation-address', 'Donation Address is required');
        this.validateZip(errors, 'donation-zip-code', 'Valid Zip Code is required');
        this.validateChecked(errors, 'mly-customer-yes', 'Please confirm if you are a customer');
        this.validateDonationAmount(errors, 'yes-donation-amount', 'no-donation-amount', 'Donation Amount is required');
        this.validateShelter(errors, 'yes-customer-choose-shelter', 'Please choose a shelter');
        this.validateShelterNo(errors, 'no-customer-choose-shelter', 'Please choose a shelter');
        this.validateInput(errors, 'donation-city', 'Please provide a donation city');



        this.validateSelection(errors, 'mly-donation-state', 'Please choose a state');
        this.validateComment(errors, 'state-questn-comment', 'Please provide a comment');
        this.validateComment(errors, 'local-questn-comment', 'Please provide a comment');
        this.validateComment(errors, 'all-questn-comment', 'Please provide a comment');
        this.validateComment(errors, 'state-questn-comment-no', 'Please provide a comment');

        const isCurrentCustomer = (document.getElementById('mly-customer-yes') as HTMLInputElement)?.checked;
        if (isCurrentCustomer) {
            const yesCustumerChooseShelter = (document.getElementById('yes-customer-choose-shelter') as HTMLSelectElement)?.value;
            if (yesCustumerChooseShelter && yesCustumerChooseShelter == 'yes-stateShelter') {

                this.validateSelection(errors, 'choose-state-shelter', 'Please choose a shelter');

            } else if (yesCustumerChooseShelter && yesCustumerChooseShelter == 'yes-allShelter') {

                this.validateSelection(errors, 'choose-all-shelter', 'Please choose a shelter');
            }
        } else {
            const noCustumerChooseShelter = (document.getElementById('no-customer-choose-shelter') as HTMLSelectElement)?.value;
            if (noCustumerChooseShelter && noCustumerChooseShelter == 'no-stateShelter') {

                this.validateSelection(errors, 'choose-state-shelter-no', 'Please choose a shelter');
            } else if (noCustumerChooseShelter && noCustumerChooseShelter == 'no-allShelter') {
                this.validateSelection(errors, 'choose-all-shelter-no', 'Please choose a shelter');
            }
        }

        this.displayErrors(errors);
        return Object.keys(errors).length === 0;
    }

    public hideErrors() {
        document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
        document.querySelectorAll('.invalid-field').forEach(e => e.classList.remove('invalid-field'));
    }

    private validateInput(errors: Record<string, string>, id: string, errorMessage: string) {
        const input = document.getElementById(id) as HTMLInputElement;
        const errorMsgSpan = document.getElementById(`${id}-error-msg`) as HTMLElement;
        if (!input?.value && input?.required) {
            errors[input?.id] = errorMsgSpan?.innerHTML ?? errorMessage;
        } else if (input?.value && !REGEX.sendName.test(input.value)) {
            errors[input?.id] = errorMessage;
        }
    }

    private validateAddressInput(errors: Record<string, string>, id: string, errorMessage: string) {
        const input = document.getElementById(id) as HTMLInputElement;
        const errorMsgSpan = document.getElementById(`${id}-error-msg`) as HTMLElement;
        if (!input?.value && input?.required) {
            errors[input?.id] = errorMsgSpan?.innerHTML ?? errorMessage;
        } else if (input?.value && !REGEX.sendAddress1.test(input.value)) {
            errors[input?.id] = errorMessage;
        }
    }

    private validateEmail(errors: Record<string, string>, id: string, errorMessage: string) {
        const input = document.getElementById(id) as HTMLInputElement;
        const errorMsgSpan = document.getElementById(`${id}-error-msg`) as HTMLElement;
        if (input?.required && !input?.value || !REGEX.sendEmail.test(input.value)) {
            errors[input?.id] = errorMsgSpan?.innerHTML ?? errorMessage;
        }
    }

    private validateZip(errors: Record<string, string>, id: string, errorMessage: string) {
        const input = document.getElementById(id) as HTMLInputElement;
        const errorMsgSpan = document.getElementById(`${id}-error-msg`) as HTMLElement;

        if (!input?.value && input.required) {
            errors[input?.id] = errorMsgSpan?.innerHTML ?? errorMessage;
        }

        if (input?.value && !REGEX.sendZip.test(input?.value?.trim())) {
            errors[input?.id] = 'Invalid zip code format';
        }
    }

    private validateChecked(errors: Record<string, string>, id: string, errorMessage: string) {
        // const input = document.getElementById(id) as HTMLInputElement;
        const input = document.querySelector('input[name="mly-customer-radio"]:checked') as HTMLInputElement;
        const errorMsgSpan = document.getElementById(`${id}-error-msg`) as HTMLElement;

        if (!input?.checked && input?.required) {
            errors['customertype-error-msg'] = errorMsgSpan?.innerHTML ?? errorMessage;;
        }
    }

    private validateDonationAmount(errors: Record<string, string>, id1: string, id2: string, errorMessage: string) {

        const input1 = document.getElementById(id1) as HTMLInputElement;
        const input2 = document.getElementById(id2) as HTMLInputElement;
        const errorMsgSpan1 = document.getElementById(`${id1}-error-msg`) as HTMLElement;
        const errorMsgSpan2 = document.getElementById(`${id2}-error-msg`) as HTMLElement;
        const isMlyCustmer = document.querySelector('input[name="mly-customer-radio"]:checked') as HTMLInputElement

        if (!input1?.value && input1?.required && isMlyCustmer?.value === 'yes') {
            errors[input1?.id] = errorMsgSpan1?.innerHTML ?? errorMessage;
        }
        if (!input2?.value && input2?.required && isMlyCustmer?.value === 'no') {
            errors[input2?.id] = errorMsgSpan2?.innerHTML ?? errorMessage;
        }
    }

    private validateShelterNo(errors: Record<string, string>, id1: string, errorMessage: string) {
        const isMlyCustmer = document.querySelector('input[name="mly-customer-radio"]:checked') as HTMLInputElement;
        if (isMlyCustmer?.value === 'no') {
            const input1 = document.getElementById(id1) as HTMLInputElement;
            if (!input1?.value && input1?.required) {
                const errorMsgSpan = document.getElementById(`${id1}-error-msg`) as HTMLElement;
                errors[input1?.id] = errorMsgSpan?.innerHTML ?? errorMessage;
            }
        }
    }

    private validateShelter(errors: Record<string, string>, id1: string, errorMessage: string) {
        const isMlyCustmer = document.querySelector('input[name="mly-customer-radio"]:checked') as HTMLInputElement;
        const input1 = document.getElementById(id1) as HTMLInputElement;
        if (isMlyCustmer?.value === 'yes') {
            if (!input1?.value && input1?.required) {
                const errorMsgSpan = document.getElementById(`${id1}-error-msg`) as HTMLElement;
                errors[input1?.id] = errorMsgSpan?.innerHTML ?? errorMessage;
            }
        }
        const input2 = document.getElementById('choose-state-shelter') as HTMLSelectElement
        if (input1?.value == 'yes-stateShelter') {
            if (!input2?.value && input2?.required) {
                const errorMsgSpan = document.getElementById(`${id1}-error-msg`) as HTMLElement;
                errors[input2?.id] = errorMsgSpan?.innerHTML ?? errorMessage;;;
            }
        }
    }

    private validateSelection(errors: Record<string, string>, id: string, errorMessage: string) {
        const input1 = document.getElementById(id) as HTMLSelectElement;
        const errorMsgSpan = document.getElementById(`${id}-error-msg`) as HTMLElement;
        if (!input1?.value && input1?.required) {
            errors[input1?.id] = errorMsgSpan?.innerHTML ?? errorMessage;
        }
    }
    private validateComment(errors: Record<string, string>, id: string, errorMessage: string) {
        const input1 = document.getElementById(id) as HTMLInputElement;
        if (input1?.value && input1?.value.length > 500) {
            errors[input1?.id] = 'Comments cannot exceed 500 characters';
        }
    }

    private displayErrors(errors: Record<string, string>) {
        const firstErrorFieldId = Object.keys(errors)[0];
        if (firstErrorFieldId) {
            const firstErrorField = document.getElementById(firstErrorFieldId);
            if (firstErrorField) {
                firstErrorField.focus();
            }
        }

        Object.keys(errors).forEach(fieldId => {
            const field = document.getElementById(fieldId);
            const fieldError = document.getElementById(`${fieldId}-error-msg`);
            if (field?.parentNode) {
                field.classList.add("invalid-field");
                if (fieldError) {
                    fieldError.innerHTML = errors[fieldId];
                    fieldError.classList.remove('hidden');
                }
            }
        });
    }

    createCustomSelect(customSelectParents: any) {
        const customSelects: NodeListOf<CustomSelectElement> = customSelectParents || document.querySelectorAll(".donation-form .custom-select");

        customSelects.forEach(select => {
            const selectElement: any = select.querySelector("select");
            const selectOptions: NodeListOf<HTMLOptionElement> = selectElement.querySelectorAll("option");
            const selectedOption: string = selectOptions[selectElement.selectedIndex].innerHTML;
            const selectButton: HTMLButtonElement = document.createElement("button");
            selectButton.type = "button";
            selectButton.classList.add("select-selected");
            selectButton.innerHTML = selectedOption;
            select.appendChild(selectButton);
            const optionsContainer: HTMLDivElement = document.createElement("div");
            optionsContainer.classList.add("select-items", "select-hide", "slim-scroll");
            selectOptions.forEach(option => {
                let self = this;
                if (option.index !== 0) {
                    const optionButton: HTMLButtonElement = document.createElement("button");
                    optionButton.type = "button";
                    optionButton.innerHTML = option.innerHTML;
                    optionButton.value = option.value;
                    if(option.disabled){
                        optionButton.disabled = true;
                        optionButton.style.cursor="not-allowed";
                        optionButton.style.opacity="0.5";
                    }
                    else{
                        optionButton.addEventListener("click", function (e: any) {
                            const selectedText: any = e.target?.innerHTML;
                            selectButton.innerHTML = selectedText;
                            selectElement.value = e.target?.value;
                            self.closeAllSelect(selectButton);
                            const isMlyCustmer = (document.querySelector('input[name="mly-customer-radio"]:checked') as HTMLInputElement)?.value;
                            if (e?.target.value === `${isMlyCustmer}-allShelter`) {
                                const chooseAllState = document.querySelectorAll(`#${isMlyCustmer}-stateShelter,#${isMlyCustmer}-localShelter`);
                                self.hideShelterDom(chooseAllState);
                            }
                            if (e?.target.value === `${isMlyCustmer}-stateShelter`) {
                                const chooseAllState = document.querySelectorAll(`#${isMlyCustmer}-allShelter,#${isMlyCustmer}-localShelter`);
                                self.hideShelterDom(chooseAllState);
                            }
                            if (e?.target.value === `${isMlyCustmer}-localShelter`) {
                                const chooseAllState = document.querySelectorAll(`#${isMlyCustmer}-stateShelter,#${isMlyCustmer}-allShelter`);
                                self.hideShelterDom(chooseAllState);
                            }
    
                            if (select.querySelector("#no-customer-choose-shelter")) {
                                self.hideErrorMessage('no-customer-choose-shelter');
                            }
                            if (select.querySelector("#yes-customer-choose-shelter")) {
                                const selected = document.getElementById(`${e.target?.value}`) as HTMLElement;
                                if (selected) {
                                    selected.classList.remove("hidden");
                                    selected.style.display = "flex";
                                    const selectElement = document.querySelector("#yes-customer-choose-shelter") as HTMLSelectElement;
    
                                    if (selectElement) {
                                        const options = selectElement.options;
                                        for (let i = 0; i < options.length; i++) {
                                            if (options[i].value && options[i].value !== e.target?.value) {
                                                const selected = document.getElementById(`${options[i].value}`) as HTMLElement;
                                                if (selected) {
                                                    selected.style.display = '';
                                                }
                                            }
                                        }
                                    }
    
    
                                }
                                self.hideErrorMessage('yes-customer-choose-shelter');
                                if (e.target?.value === 'yes-stateShelter') {
                                    const stateVal = (document.getElementById('mly-donation-state') as HTMLSelectElement)?.value;
                                    self.createStateSelectOption(stateVal);
                                }
                            }
                            if (select.querySelector("#no-customer-choose-shelter")) {
                                const selected = document.getElementById(`${e.target?.value}`) as HTMLElement;
                                if (selected) {
                                    selected.classList.remove("hidden");
                                    selected.style.display = "flex";
                                    const selectElement = document.querySelector("#no-customer-choose-shelter") as HTMLSelectElement;
    
                                    if (selectElement) {
                                        const options = selectElement.options;
                                        for (let i = 0; i < options.length; i++) {
                                            if (options[i].value && options[i].value !== e.target?.value) {
                                                const selected = document.getElementById(`${options[i].value}`) as HTMLElement;
                                                if (selected) {
                                                    selected.style.display = '';
                                                }
                                            }
                                        }
                                    }
                                }
                                if (e.target?.value === 'no-stateShelter') {
                                    const stateVal = (document.getElementById('mly-donation-state') as HTMLSelectElement)?.value;
                                    self.createStateSelectOption(stateVal);
                                }
    
                                self.hideErrorMessage('no-customer-choose-shelter');
                            }
    
                            if (select.querySelector("#choose-state-shelter")) {
                                self.hideErrorMessage('choose-state-shelter');
                            }
                            if (select.querySelector("#choose-state-shelter-no")) {
                                self.hideErrorMessage('choose-state-shelter-no');
                            }
                            if (select.querySelector("#choose-all-shelter-no")) {
                                self.hideErrorMessage('choose-all-shelter-no');
                            }
                            
                            if (select.querySelector("#choose-all-shelter")) {
                                self.hideErrorMessage('choose-all-shelter');
                            }
    
                            if (select.querySelector("#mly-donation-state")) {
                                self.hideErrorMessage('mly-donation-state');
                                self.createStateSelectOption(e.target?.value);
                                console.log('mly-donation-state');
                            }
                        });
                    }
                   
                    optionsContainer.appendChild(optionButton);
                }
            });
            select.appendChild(optionsContainer);
            let self = this;
            selectButton.addEventListener("click", function (e: MouseEvent) {
                e.stopPropagation();
                self.closeAllSelect(this);
                const nextSibling = this.nextSibling as HTMLDivElement | null;
                if (nextSibling) {
                    nextSibling.classList.toggle("select-hide");
                }
                this.classList.toggle("select-arrow-active");

                if(!this.classList.contains("select-arrow-active")) {
                    if (selectButton?.nextSibling) {
                        (selectButton.nextSibling as HTMLDivElement)?.classList.add("select-hide");
                    }
                }

                document.addEventListener('keydown', (event) => {
                    if (event.key === 'Escape') {
                        nextSibling?.classList.add("select-hide");
                        selectButton.classList.remove("select-arrow-active");
                    }
                });

                selectButton.style.backgroundColor = "var(--primary-500)";
                selectButton.style.color = "var(--white)";
                selectButton.style.whiteSpace = "nowrap";
                selectButton.style.overflow = "hidden";
                selectButton.style.borderRight = "40px solid var(--primary-500)";

            });
        });
    }

    hideShelterDom(shelterDomList: any) {
        shelterDomList?.forEach((element: any) => {
            element?.classList.add("hidden");
        });
    }

    // Function to close all select boxes
    closeAllSelect(except: ExceptType) {
        const allSelectItems: NodeListOf<HTMLDivElement> = document.querySelectorAll(".donation-form .select-items.slim-scroll");
        const allSelectButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".donation-form .select-selected");
        allSelectItems.forEach((item, index) => {
            if (except !== allSelectButtons[index]) {
                item.classList.add("select-hide");
                allSelectButtons[index]?.classList?.remove("select-arrow-active");
                if( allSelectButtons[index]?.nextElementSibling) {
                    allSelectButtons[index]?.nextElementSibling?.classList.add("select-hide");
                }
            }
            
        });

      
    }

    // Main function to initialize custom select elements
    initializeCustomSelect() {
        let elements = document.querySelectorAll('.donation-form .select-selected') as NodeList;
        elements.forEach(function (element) {
            if (element.parentNode) {
                element.parentNode.removeChild(element);
            }
        });

        this.createCustomSelect(null);
        let self = this;
        document.addEventListener("click", function () {
            self.closeAllSelect(null);
        });
    }

    hideErrorMessage(id: any) {
        const errorElement = document.getElementById(`${id}-error-msg`);
        if (errorElement) {
            errorElement?.classList.add('hidden');
        }
    }

    private async prePurchaseCertificate() {
        try {
            const apiUrl = `${process.env.JS_CORE_API_BASE_URL}/MsMollyDonations/PrePurchase`;
            const request = {
                method: 'POST',
                url: apiUrl,
                data: window?.donorPayload ?? {},
            };

            const prePurchaseRes: any = await apiRequest(request);
            if (prePurchaseRes) {
                const invoicenNumber = prePurchaseRes?.MsMollyDonationId;
                if (invoicenNumber) {
                    const paymentPostUrl = `${apiConfig.paymentPostUrl}?invoicenumber=${invoicenNumber}&appkey=${process.env.JS_DONATION_MERCHANT_KEY}`
                    console.log(paymentPostUrl, 'PaymentPostUrl');
                    const step1 = document.querySelectorAll(".contact-us-section.form-step");
                    let form = step1[0] as HTMLFormElement;

                    if (form) {
                        form.action = paymentPostUrl;
                        form.method = 'POST';
                        form.submit();
                    }
                }
            }

        } catch (error) {
            // const errorModal = document.querySelector('.gift-certificate-form #error-modal') as HTMLElement;
            // if(errorModal){
            //     errorModal.classList.remove('hidden');
            //     (document.querySelector('#gc-error-modal-btn') as HTMLAnchorElement)?.click()
            // }
        }

    }

    public async getUsState() {
        const baseUrl = `${apiConfig.stateApi}US?apikey=${process.env.JS_API_KEY}`;
        const request = {
            url: baseUrl,
        };
        const usStateList: any = await apiRequest(request);
        const stateListResponse = JSON.parse(JSON.stringify(usStateList));
        let obj:StateCodes = stateListResponse.reduce((acc:any, cur:any) => {
            acc[cur.stateCode.trim()] = cur.stateName.trim();
            return acc;
        }, {});
        this.stateList = obj
        this.createAllStateSelectOption(usStateList);
    }

    private createAllStateSelectOption(finalResult: any) {
        const selectState = document.getElementById('mly-donation-state') as HTMLSelectElement;
        if (selectState) {
            while (selectState?.firstChild) {
                selectState.removeChild(selectState.firstChild);
            }
            const option = document.createElement("option");
            option.text = 'Select State';
            option.value = '';
            selectState.appendChild(option);
            finalResult?.forEach((item: any) => {
                const option = document.createElement("option");
                option.text = item.stateName;
                option.value = item.stateCode;
                selectState.appendChild(option);

            });
        }
    }

    private createStateSelectOption(stateCode: any) {
        const isCurrentCustomer = (document.getElementById('mly-customer-yes') as HTMLInputElement)?.checked ? 'choose-state-shelter' : 'choose-state-shelter-no';
        const selectState = document.getElementById(isCurrentCustomer) as HTMLSelectElement;
        const isYesCurrentCustomer = (document.getElementById('mly-customer-yes') as HTMLInputElement)?.checked;
        let isActiveState = false;
        if (selectState) {
            while (selectState?.firstChild) {
                selectState.removeChild(selectState.firstChild);
            }
            const option = document.createElement("option");
            option.text = isYesCurrentCustomer ? 'Choose from these statewide Molly Maid supported shelters' : 'Choose from these statewide shelters';
            option.value = '';
            selectState.appendChild(option);
            const filteredAndSortedShelters = window.shelterList
            ?.filter((item: any) => item.IsActive && item.State === stateCode)
            .sort((a: any, b: any) => a.Name.localeCompare(b.Name));

            filteredAndSortedShelters?.forEach((item: any) => {
                if (item.IsActive && item.State === stateCode) {
                    isActiveState = true;
                    const option = document.createElement("option");
                    option.text = item.Name;
                    option.value = item.MsMollyShelterId;
                    selectState.appendChild(option);
                }

            });

            if (!stateCode || !isActiveState) {
        
                window?.shelterList?.forEach((item: any) => {
                    if (item.IsActive) {
                        const option = document.createElement("option");
                        option.text = item.Name;
                        option.value = item.MsMollyShelterId;
                        selectState.appendChild(option);
                    }

                });
            }
        }
        const shelterElements = document.querySelectorAll(`#${isCurrentCustomer}`);
        const customSelectParents = Array.from(shelterElements).map(shelterElement => {
            const parentElement = shelterElement.parentElement;
            if (parentElement) {
                let elements = parentElement.querySelectorAll('.select-selected') as NodeList;
                elements.forEach(function (element) {
                    if (element.parentNode) {
                        element.parentNode.removeChild(element);
                    }
                });
            }
            if (parentElement?.classList.contains('custom-select')) {
                return parentElement;
            }
            return null;
        }).filter(parentElement => parentElement !== null);
        this.createCustomSelect(customSelectParents);

    }

    public async getShelterList() {
        const apiUrl = `${process.env.JS_CORE_API_BASE_URL}/MsMollyDonations/GetShelters`;
        const request = {
            url: apiUrl,
        };
        const shelterList: any = await apiRequest(request);
        const filteredAndSortedShelters = shelterList
        ?.filter((item: any) => item.IsActive)
        .sort((a: any, b: any) => a.Name.localeCompare(b.Name));

        window.shelterList = filteredAndSortedShelters;
        this.createShelterSelectOption(this.sortShelterByStateName(filteredAndSortedShelters));
        this.createStateSelectOption(null);
    }

    private toSorted(shelters: Shelter[]): Shelter[] {
        return shelters.sort((a, b) => a.Name.localeCompare(b.Name));
    }
    
    sortShelterByStateName(shelters: Shelter[]): StateData[]{
        const cityDataMap: { [key: string]: Shelter[] } = {};
        for (const shelter of shelters) {
            if (!cityDataMap[shelter.State]) {
                cityDataMap[shelter.State] = [];
            }
            cityDataMap[shelter.State].push(shelter);
        }
        const cityDataArray: StateData[] = [];

        for (const state in cityDataMap) {
            cityDataArray.push({
                State: this.stateList[state] ?? "",
                Data: this.toSorted(cityDataMap[state])
            });
        }
        cityDataArray.sort((a, b) => a.State.localeCompare(b.State))
        return cityDataArray

    }

    private createShelterSelectOption(finalResult: any) {

        const isMlyCustmer = document.querySelector('input[name="mly-customer-radio"]:checked') as HTMLInputElement;
        let chooseShelter = 'choose-all-shelter';
        if (isMlyCustmer?.value === 'no') {
            chooseShelter = 'choose-all-shelter-no'
        }
        const selectState = document.getElementById(chooseShelter) as HTMLSelectElement;
        if (selectState) {
            while (selectState?.firstChild) {
                selectState.removeChild(selectState.firstChild);
            }
            const option = document.createElement("option");
            option.text = 'Choose from these nationwide shelters';
            option.value = '';
            selectState.appendChild(option);
            finalResult?.forEach((item: any) => {
                if(item.State!==""){
                    const option = document.createElement("option");
                    option.text = `--${item.State}--`;
                    option.value = "";
                    option.disabled = true;
                    selectState.appendChild(option);
                    item.Data.forEach((shelter: any) => {
                        const option = document.createElement("option");
                        option.text = `${shelter.Name} - ${shelter.City}, ${shelter.State}`;
                        option.value = shelter.MsMollyShelterId;
                        selectState.appendChild(option);
                    });
                }
                
            });
        }

        this.initializeCustomSelect();

    }

    private switchToNextStep() {
        hmsProgressBarUpdate('100%', 1);
        window.scrollTo(0, 0);
    }

}


document.addEventListener("DOMContentLoaded", () => {
    const conceptId: any = (document.getElementById('conceptId') as HTMLInputElement)?.value;
    if (conceptId == 1) {
        const currentUrl = window.location.href;
        const urlParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlParams.entries());
        const resultMsg = params.result_msg;
        const isDonor = currentUrl.includes("ms-molly-foundation") //params?.description?.includes('Donation')

        if (window.location.pathname.endsWith("confirmation/") && isDonor) {
            const donorConfirmation = new DonorConfirmation();

            if (currentUrl.indexOf("?") !== -1) {
                window.localStorage.setItem('donorPaymentResult', resultMsg);
                if (resultMsg?.toUpperCase() === "APPROVAL") {
                    donorConfirmation.displayMessage(true);
                } else {
                    donorConfirmation.displayMessage(false);
                }
                donorConfirmation.pushHTMLToHistoryAndCleanUrl();

            } else {
                const storedResultMsg = window.localStorage.getItem('donorPaymentResult');
                if (storedResultMsg) {
                    donorConfirmation.displayMessage(true);
                }
            }

            window.onpopstate = (event: PopStateEvent) => {
                if (event.state) {
                    document.documentElement.outerHTML = event.state.html;
                    document.title = event.state.pageTitle;
                }
            };
        } else {
            const isMlyCustmer = (document.querySelector('input[name="mly-customer-radio"]:checked') as HTMLInputElement)?.value;
            if (isMlyCustmer === 'yes') {
                const isNotMlyCustomer = document.getElementById('mlyCustomerNo') as HTMLElement;
                if (isNotMlyCustomer) {
                    isNotMlyCustomer.style.display = 'none';
                }

            } else {
                const isMlyCustomer = document.getElementById('mlyCustomerYes') as HTMLElement;
                if (isMlyCustomer) {
                    isMlyCustomer.style.display = 'none';
                }
            }
            new Donation();
        }

        // END After payment Confirmation page 
    }
});