import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
const wktParser = require('terraformer-wkt-parser');
export class AreaWeServe{
    public franchiseWebLocationId: any;
    private mapContainer: HTMLElement | null;
    public wkts: string[] = [];
    public mapType: string = "";
    private centroid: string = "";
    private correlationId: string = "";
    public franchiseId: any;
    private zoom_Level:any;

    constructor() {
        const transactionID = sessionStorage.getItem('corelationID');
        const sessionID = sessionStorage.getItem('sessionID');
        this.correlationId = `correlationId=${transactionID}&sessionId=${sessionID}`;

        const localWebID = sessionStorage.getItem("franchiseWebLocationId");
        const webID = document.getElementById('weblocationId') as HTMLInputElement;
        this.franchiseWebLocationId = webID?.value ? webID?.value : localWebID;
        this.zoom_Level = Number((document.getElementById('map-zoom-level') as HTMLInputElement)?.value);
        const areaContainer = document.querySelector(".area-serve-container");
        if (areaContainer) {
            this.getWellKnownText();
        }
        this.mapContainer = document.querySelector(".map-img-box");
        if (this.franchiseWebLocationId) {
            this.getCityData();
        }
        // this.initMap();
    }
    public multipleWkts() {
        this.wkts.forEach((wkt) => {
            if (this.mapType === "polygon") {
                this.initGMapPolygon();
            }else{
                this.initGMap(wkt);
            }
        });
    }

    private initGMapPolygon() {
        let map: google.maps.Map;
        
        let minX: number, minY: number, maxX: number, maxY: number;

        const addPoints = (ptsArray: google.maps.LatLng[], data: string) => {
            const pointsData = data.split(",");
            let str = "";
            for (let i = 0; i < pointsData.length; i++) {
                const xy = pointsData[i].trim().split(" ");
                const pt = new google.maps.LatLng(parseFloat(xy[1]), parseFloat(xy[0]));
                str = str + ", " + xy[1] + " " + xy[0];
                ptsArray.push(pt);

                if (i === 0) {
                    minX = maxX = parseFloat(xy[1]);
                    minY = maxY = parseFloat(xy[0]);
                } else {
                    minX = Math.min(parseFloat(xy[1]), minX);
                    minY = Math.min(parseFloat(xy[0]), minY);
                    maxX = Math.max(parseFloat(xy[1]), maxX);
                    maxY = Math.max(parseFloat(xy[0]), maxY);
                }
            }
        };

        const createPoly = (wkt: string) => {
            const regex = /\(([^()]+)\)/g;
            const Rings: string[] = [];
            let results;
            while ((results = regex.exec(wkt))) {
                Rings.push(results[1]);
            }

            const ptsArray: google.maps.LatLng[] = [];
            let maxLen = 0;
            let j = 0;

            for (let i = 0; i < Rings.length; i++) {
                if (maxLen <= Rings[i].length) {
                    maxLen = Rings[i].length;
                    j = i;
                }
            }

            addPoints(ptsArray, Rings[j]);

            const poly = new google.maps.Polygon({
                paths: ptsArray,
                strokeColor: "#FF0000",
                strokeOpacity: 0.8,
                strokeWeight: 2,
                fillColor: "#1E90FF",
                fillOpacity: 0.35,
            });

            return poly;
        };

        this.wkts.forEach((wkt) => {
            const poly = createPoly(wkt);
            const mapBox = document.querySelector(".map-img-box") as HTMLElement;
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const userLatitude = position.coords.latitude;
                    const userLongitude = position.coords.longitude;

                    // Use the user's location as the center
                    const myOptions: google.maps.MapOptions = {
                        zoom: this.zoom_Level ? this.zoom_Level : 8.8,
                        center: new google.maps.LatLng(37.7749, -122.4194),
                        zoomControl: true,
                        scaleControl: true,
                    };

                    map = new google.maps.Map(mapBox, myOptions);
                    const bounds = new google.maps.LatLngBounds();
                    poly.getPath().forEach((path) => {
                        bounds.extend(path);
                    });
                    map.fitBounds(bounds);
                    poly.setMap(map);
                },
                (error) => {
                    const centerLatitude = (maxX + minX) / 2;
                    const centerLongitude = (maxY + minY) / 2;
                    const zoomLevel = this.zoom_Level ? this.zoom_Level : 8.8;

                    const myOptions: google.maps.MapOptions = {
                        zoom: zoomLevel,
                        center: new google.maps.LatLng(centerLatitude, centerLongitude),
                        zoomControl: true,
                        scaleControl: true,
                    };
                    map = new google.maps.Map(mapBox, myOptions);
                    const bounds = new google.maps.LatLngBounds();
                    poly.getPath().forEach((path) => {
                        bounds.extend(path);
                    });
                    map.fitBounds(bounds);
                    poly.setMap(map);
                }
            );
        });
    }

    private getCityData(): void {
        try {
            const request = {
                method: 'GET',
                url: apiConfig.fullAttribute + this.franchiseWebLocationId,
                headers: {
                    'Content-Type': 'application/json',
                    'apikey': process.env.JS_API_KEY,
                    'X-Corellation-Id': this.correlationId
                }
            };

            const updateContent = (element: HTMLElement | null, content: string) => {
                if(element) {
                    element.textContent = content;
                }
            }
            

            //This function handles the updation of the address
            const updateAddressContent = (resp: any, filterFlag: any) => {
                const { address, address2, state, country, city, zip, displayAddressSwitch } = resp;
                const checkForDOMElement = document.getElementById("show-address");
                if(!filterFlag.disable_location_address && checkForDOMElement) {
                    const areaWeServe = document.querySelector('.area-serve-title') as HTMLElement;
                    if(areaWeServe){
                        areaWeServe.innerHTML+= `<p class="title-sub-text"></p>`;
                    }
                    const addressContainer = document.querySelector('.area-serve-title .title-sub-text') as HTMLElement;
                    if(displayAddressSwitch) {
                        updateContent(addressContainer, `${address}${address2 ? ', ' + address2 : ''} ${city}, ${state} ${zip}, ${country}`);
                    } else {
                        updateContent(addressContainer, `${city}, ${state} ${zip}, ${country}`);
                    }
                }
            }
            
            //This updates the h2 title
            const updateTitleContent = (resp: any) => {
                const { locationDoingBusinessAs } = resp;
                const addressTitle = document.querySelector('.area-serve-title .title-heading') as HTMLElement;
                if(!addressTitle?.textContent && locationDoingBusinessAs) {
                    updateContent(addressTitle, locationDoingBusinessAs);
                }
            }
            
            apiRequest(request)
                .then((resp: any) => {
                    const brandSpecificData = localStorage.getItem("brandDetails");
                    if(brandSpecificData){
                        const filterFlag = JSON.parse(brandSpecificData);
                        updateAddressContent(resp, filterFlag);
                    }
                    updateTitleContent(resp);
                })
                .catch((err) => {
                    console.error(err)
                });
            
            
        } catch (error) {
            console.log(error);
        }
    }

    private getWellKnownText() {
        // const digitalorchestrations = process.env.JS_DigitalOrchestrations_URL;

        const apiDomain = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? process.env.JS_API_URL : "https://api.neighborly.com";
        const apiKey = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? process.env.JS_API_KEY : "pneiweb-9pbaOywNKQj853D";
        const franchiseGroup = (window.location.hostname.indexOf("nblydev") != -1 || window.location.hostname.indexOf("nblysbx") != -1) ? process.env.JS_FranchiseGroup_URL : "franchisegroup-api";

        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        const franchiseWebLocationsApi = `${process.env.JS_API_URL}/${process.env.JS_FranchiseGroup_URL}/v1/public/concepts/${conceptCode}/franchiseweblocations?apikey=${process.env.JS_API_KEY}`;

        fetch(franchiseWebLocationsApi)
            .then(response => {
                if (!response.ok) {
                    throw new Error("Network response was not ok");
                }
                return response.json();
            })
            .then(data => {
                // Find the franchiseId associated with the given webLocationId
                this.franchiseId = this.findFranchiseIdByWebLocationId(data, this.franchiseWebLocationId);
                const apiUrl = `${apiDomain}/${franchiseGroup}/v1/public/franchiseTerritories/postalCodes?apikey=${apiKey}&franchiseId=${this.franchiseId}`;
                // var apiUrl = `${apiDomain}/${digitalorchestrations}/v1/public/franchiseTerritories/postalCodes/${this.franchiseWebLocationId}`;

                let isMapDraw = 0;

                const request = {
                    method: 'GET',
                    url: apiUrl,
                    headers: {
                        'Content-Type': 'application/json',
                        // 'apikey': process.env.JS_API_KEY,
                        // 'X-Corellation-Id': this.correlationId
                    }
                };
                apiRequest(request)
                    .then((result: any) => {
                        let territoryMapData:any;
                        
                        const franchiseWeblocationId:any = document.getElementById("weblocationId");
                        result.franchiseTerritories.forEach((value: any) => {
                           
                            if(value.franchiseWebLocationId === Number(franchiseWeblocationId.value)){
                                 territoryMapData =  value;
                            }
                        });
                        if(territoryMapData){
                            if (territoryMapData.franchiseTerritoryTypeId === 5 && isMapDraw === 0) {
                                if (
                                    territoryMapData.territoryBoundary !== null &&
                                    territoryMapData.territoryBoundary !== "" &&
                                    territoryMapData.territoryBoundary.wellKnownText !== null &&
                                    territoryMapData.territoryBoundary.wellKnownText !== ""
                                ) {
                                    const wktData = territoryMapData.territoryBoundary.wellKnownText;
                                    this.wkts.push(wktData);
                                    this.mapType = wktData.split(" ")[0].toLowerCase();
                                    isMapDraw = 1;
                                    this.centroid = territoryMapData.territoryBoundary.centroid;
                                }
                            }

                            if (isMapDraw === 1) {
                                this.multipleWkts();
                            }
                        }
                    })
                    .catch((error) => {
                        console.log("Error:", error);
                    });
            })
            .catch(error => {
                console.error("Fetch error:", error);
            });

    }
    findFranchiseIdByWebLocationId(data: any, webLocationId: any) {
        for (const item of data) {
            const franchiseDetails = item.franchiseDetails;
            for (const detail of franchiseDetails) {
                if (detail.franchiseWebLocationId == webLocationId) {
                    return item.franchiseId;
                }
            }
        }
        return null; // Return null if not found
    }
    private initGMap(wkt:any){
        let geometry = wktParser.parse(wkt);
       let center = this.centroid.replace("POINT (", "").replace(')', "").split(" ");
       let data = {
                "type": "FeatureCollection",
                    "features": [ {
                    "type": "Feature",
                        "properties": {
                        "fillColor": "#1E90FF"
                    },
                        "geometry": geometry
                }]
            };
            let mapProp = {
                center: {lat: parseFloat(center[1]), lng: parseFloat(center[0])},
                zoom: this.zoom_Level ? this.zoom_Level : 8.8,
                zoomControl: true,
                scaleControl: true,
            };
            const mapBox = document.querySelector(".map-img-box") as HTMLElement;
            let map = new google.maps.Map(mapBox, mapProp);
        
            map.data.addGeoJson(data);
            map.data.setStyle(function (feature:any) {

                return {
                    fillColor: '#1E90FF',
                    strokeColor: '#FF0000',
                    strokeOpacity: 0.8,
                    strokeWeight: 2,
                    fillOpacity: 0.35
                };
            });
        }
}

new AreaWeServe();