import apiRequest from "../api/apiRequest";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { apiConfig } from "../config/apiConfig";
import { startLoader, stopLoader } from "../util/loader";
import { getBrandDetailsFromBrandJson, storeBookingApiPayload } from "../util/share";
let moment = require('moment');

export function callBookingApi(bookingPayload: any){
    startLoader();
    const request = {
        method: 'POST',
        url: apiConfig.BOOKING_API_URL,
        data: bookingPayload
    };
    apiRequest(request)
        .then((response: any) => {
            if(response){
                storeBookingApiPayload(bookingPayload, response);
                console.log('API success');
                window.location.pathname = '/confirmation';
            }
            else{
                console.log('Booking API returns no response');
            }
            
        })
        .catch((error:any) => {
            stopLoader();
            console.log('API fail');
        })
}

export async function getFranchiseDetails(addressParam:string) : Promise<any> {
    const request = {
        url: apiConfig.LocateLocationApiWithRoundRobinTrue.replace('sAddressParamValue', encodeURIComponent(addressParam))
    };
    try{
       const result:any = getCachedAPIResponse(request.url);
        return result;
    }
    catch(error){
        return Promise.reject('Error');
    }    
}

export function determineFlowType(WebLocationId:number) : any {
    // Function to determine whether OS flow or Lead flow
    const request = {
        method: 'GET',
        url: `${apiConfig.GET_ATTRIBUTE_DATA}?apikey=${process.env.JS_API_KEY}&franchiseWeblocationId=${WebLocationId}`,
    };
    try{
        const result = apiRequest(request);
        return result;
    }
    catch(error){
        throw error;
    } 

}

export async function checkTechAvailability() : Promise<any> {
    const weblocationId = sessionStorage.getItem('franchiseWebLocationId');
    const zipValue = sessionStorage.getItem('zipcode');
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    const maxWeeks = brandData?.calendarMaxWeeksCap ? brandData?.calendarMaxWeeksCap: 8;
    const dynamic_url = `${apiConfig.AppointMentAPi}?PostalCode=${zipValue}&WebLocationId=${weblocationId}&NumberOfDays=${7*maxWeeks}&DateStart=${moment().format('MM/DD/YYYY')}&IsEstimate=true&apikey=${process.env.JS_API_KEY}`;
    return getCachedAPIResponse(dynamic_url);
    
}

export function configureMollyEstimate() : boolean{
// This method will call an API to confirm whether the estimate needs to be shown in the calendar screen or not.
    const flag = true; // To be replaced  with your API logic
    return flag;
}

export async function confirmMlyLeadSourceAPI() : Promise<string>{
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    let endpoint:string = apiConfig.GenericLeadSourceEndpoint;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    if(brandData){
        if(brandData?.send_brand_leadsource_api){
            endpoint = apiConfig.MollyLeadSourceEndpoint;
        }
        else{
            endpoint = apiConfig.GenericLeadSourceEndpoint;
        }
    }
    return endpoint;
    
}

export async function setDefaultLeadSource(): Promise<any> {
    const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const brandData = await getBrandDetailsFromBrandJson(conceptCode);
    const obj = {
        "LeadSourceID": brandData?.default_leadSourceID,
        "LeadSource": brandData?.default_leadSource
    }
    return obj;
}