import { handleInput, handleCheckboxInput, validateForm, validateSqrft, validateDropdown } from "./form-validation";
import { bookingPayload, discoveryAPI, isTestFcn } from "./booking-payload";
import { callBookingApi, confirmMlyLeadSourceAPI, determineFlowType, getFranchiseDetails, setDefaultLeadSource } from "./callBookingApi";
import { calcEstimate } from "./calcEstimate";
import { startLoader, stopLoader } from "../util/loader";
import { calendarFlowMLY } from "./mly-calendar-flow";
import { getBrandDetailsFromBrandJson, updateHeaderContent } from "../util/share";
import { getCachedAPIResponse } from "../apiCaching/apiWrapper";
import { NearbyLocations } from "../util/nearbyLocations";

let allAddressList: any = [];
// export let allMLYAddress: any = []
let suggestedAddress: any = '';
let address1Details: any = null;
type ExceptType = HTMLButtonElement | HTMLDivElement | null; // Add other types as needed

export class CleaningType {
    public cleaningType: NodeListOf<Element>;
    public cleaningFreq: NodeListOf<Element>;
    constructor() {
        this.cleaningType = document.getElementsByName('mly-cleaning-radio') as NodeListOf<Element>;
        this.cleaningFreq = document.querySelectorAll('input[name*="frequency-cleaning"]');
    }
    getCleaningType(): string {
        const radioButtons = this.cleaningType;
        for (const radioButton of radioButtons) {
            if ((radioButton as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${radioButton.id}"]`) as HTMLElement;
                const label = labelElement.innerText;
                return label;
            }
        }
        return '';
    }
    getCleaningFreq(): string {
        const selectedType = this.getCleaningType().toLowerCase();
        const val: any = { "residential": "frequency-cleaning", "light commercial": "commercial-frequency-cleaning" };
        const radioButtons = this.cleaningFreq;
        for (const radioButton of radioButtons) {
            if (radioButton.id.includes(val[selectedType] as string) && (radioButton as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${radioButton.id}"]`) as HTMLElement;
                const label = labelElement.innerText;
                return label;
            }
        }
        return '';

    }
    toggleCleaningDiv() {
        const selectedType = this.getCleaningType().toLowerCase();
        const selectedFrequency = this.getCleaningFreq().toLowerCase();
        const residentialDiv = document.getElementById('cleaningResidential') as HTMLElement;
        const commercialDiv = document.getElementById('cleaningCommercial') as HTMLElement;
        const oneTimeNextBtn = document.getElementById('one-next-btn') as HTMLButtonElement;
        const recurringNextBtn = document.getElementById("recurring-next-btn") as HTMLButtonElement;
        const mobOTBtn = document.querySelector('.btn-container-mobile #one-next-btn') as HTMLButtonElement;
        const mobRecurBtn = document.querySelector(".btn-container-mobile #recurring-next-btn") as HTMLButtonElement;
        const oneTimeDiv = document.querySelector('.one-time-frequency-only') as HTMLElement;
        const commercialRecurringDiv = document.getElementById('commercialRecurring') as HTMLElement;
        const commercialOTCDiv = document.getElementById('commercialOneTime') as HTMLElement;
        const contractDisclaimer = document.querySelector('p.contract-cancel-text') as HTMLElement;

        // Contract disclaimers // 
        const resiRecurring = document.getElementById('resi-recurring') as HTMLElement;
        const commRecurring = document.getElementById('comm-recurring') as HTMLElement;
        const resiOTMove = document.getElementById('resi-one-time-move') as HTMLElement;
        const resiOTone = document.getElementById('resi-one-time-one') as HTMLElement;
        const commOTMoveY = document.getElementById('comm-one-time-move-yes') as HTMLElement;
        const commOTMoveN = document.getElementById('comm-one-time-move-no') as HTMLElement;
        const arrayBanners = [resiRecurring, commRecurring, resiOTMove, resiOTone, commOTMoveY, commOTMoveN];
        const OTelements = document.querySelectorAll('input[name*="one-time-radio"]');
        const OTcomms = document.querySelectorAll('input[name*="move-in-out-radio"]');
        let OTRText, OTComm = '';
        for (const elem of OTelements) {
            if ((elem as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${elem.id}"]`) as HTMLElement;
                OTRText = labelElement.innerText;
            }
        }
        for (const elem of OTcomms) {
            if ((elem as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${elem.id}"]`) as HTMLElement;
                OTComm = labelElement.innerText;
            }
        }
        // Content disclaimers // 

        if (selectedType.includes('residential')) {
            residentialDiv.style.display = 'flex';
            commercialDiv.style.display = 'none';
            if (selectedFrequency.includes('recurring')) {
                recurringNextBtn.style.display = "flex";
                oneTimeNextBtn.style.display = "none";
                mobOTBtn.style.display = 'none';
                mobRecurBtn.style.display = 'flex';
                oneTimeDiv.style.display = "none";
                contractDisclaimer.style.display = 'flex';
                this.toggleCleaningBanners(arrayBanners, 0);
            }
            else {
                recurringNextBtn.style.display = "none";
                oneTimeNextBtn.style.display = "flex";
                mobOTBtn.style.display = 'flex';
                mobRecurBtn.style.display = 'none';
                oneTimeDiv.style.display = "flex";
                contractDisclaimer.style.display = 'none';
                if (OTRText?.includes('Move In')) {
                    this.toggleCleaningBanners(arrayBanners, 2);
                }
                else {
                    this.toggleCleaningBanners(arrayBanners, 3);
                }
            }
        } else {
            residentialDiv.style.display = 'none';
            commercialDiv.style.display = 'flex';
            if (selectedFrequency.includes('recurring')) {
                recurringNextBtn.style.display = "flex";
                oneTimeNextBtn.style.display = "none";
                mobOTBtn.style.display = 'none';
                mobRecurBtn.style.display = 'flex';
                commercialRecurringDiv.style.display = "flex";
                commercialOTCDiv.style.display = "none";
                contractDisclaimer.style.display = 'flex';
                this.toggleCleaningBanners(arrayBanners, 1);
            }
            else {
                recurringNextBtn.style.display = "none";
                oneTimeNextBtn.style.display = "flex";
                mobOTBtn.style.display = 'flex';
                mobRecurBtn.style.display = 'none';
                commercialRecurringDiv.style.display = "none";
                commercialOTCDiv.style.display = "flex";
                contractDisclaimer.style.display = 'none';
                if (OTComm.includes('Yes')) {
                    this.toggleCleaningBanners(arrayBanners, 4);
                }
                else {
                    this.toggleCleaningBanners(arrayBanners, 5);
                }
            }
        }
    }
    toggleCleaningBanners(arrayBanners: any[], idx: number) {
        arrayBanners?.forEach((arr) => {
            arr.style.display = 'none';
        });
        arrayBanners[idx].style.display = 'block';
    }

}

function helperSelectFcn(selectBtn: any) {
    if (selectBtn) {
        selectBtn.style.backgroundColor = "var(--primary-500)";
        selectBtn.style.color = "var(--white)";
        selectBtn.style.whiteSpace = "nowrap";
        selectBtn.style.overflow = "hidden";
        selectBtn.style.borderRight = "40px solid var(--primary-500)";
        
    }

}

export class MollyFlow {
    private firstName: HTMLInputElement;
    private lastName: HTMLInputElement;
    private email: HTMLInputElement;
    private phoneNumber: HTMLInputElement;
    private address: HTMLInputElement;
    private address2: HTMLInputElement;
    private zipCode: HTMLInputElement;
    private preferMethods: NodeListOf<Element>;
    public cleaningType: CleaningType;
    private officeSqrft: HTMLInputElement;
    private homeSqrft: HTMLInputElement;
    private weeklyFreq: NodeListOf<Element>;
    private bathrooms: HTMLSelectElement;
    private offices: HTMLSelectElement;
    private bedrooms: HTMLSelectElement;
    private resBathrooms: HTMLSelectElement;
    private leadSourceBtn: HTMLButtonElement;
    public bookingPayload: any;
    private counter: any;
    constructor() {
        this.firstName = document.getElementById('mly-user-fname') as HTMLInputElement;
        this.lastName = document.getElementById('mly-user-lname') as HTMLInputElement;
        this.email = document.getElementById('mly-user-email') as HTMLInputElement;
        this.phoneNumber = document.getElementById('mly-user-number') as HTMLInputElement;
        this.phoneNumber.maxLength = 14;
        this.address = document.getElementById('mly-user-address') as HTMLInputElement;
        this.address2 = document.getElementById('mly-user-address-2') as HTMLInputElement;
        this.zipCode = document.getElementById('mly-user-zip-code') as HTMLInputElement;
        this.zipCode.maxLength = 5;
        this.preferMethods = document.querySelectorAll('input[name*="mly-contact"]');
        this.leadSourceBtn = document.getElementById('mly-how-about-us')?.parentElement?.querySelector('button') as HTMLButtonElement;
        this.initLeadSources();
        this.cleaningType = new CleaningType();
        this.officeSqrft = document.getElementById('office-detail-sqrft') as HTMLInputElement;
        this.officeSqrft.maxLength = 5;
        this.homeSqrft = document.getElementById('home-detail-sqrft') as HTMLInputElement;
        this.homeSqrft.maxLength = 5;
        this.weeklyFreq = document.getElementsByName('frequency-week-radio') as NodeListOf<Element>;
        this.bathrooms = document.getElementById('office-detail-bathroom') as HTMLSelectElement;
        this.offices = document.getElementById('office-detail-offices') as HTMLSelectElement;
        this.resBathrooms = document.getElementById('home-detail-bathroom') as HTMLSelectElement;
        this.bedrooms = document.getElementById('home-detail-bedroom') as HTMLSelectElement;
        this.populateDropdowns(this.offices.id);
        this.populateDropdowns(this.bathrooms.id);
        this.populateDropdowns(this.resBathrooms.id);
        this.populateDropdowns(this.bedrooms.id);
        this.initListeners();
        this.loadHereMapScripts();
        this.bookingPayload = bookingPayload;
        this.bookingPayload.IsTest = isTestFcn();
        this.counter = 0;
        setDefaultLeadSource()
        .then((obj:any)=>{
            this.bookingPayload.LeadSource = obj.LeadSource;
            this.bookingPayload.LeadSourceId = obj.LeadSourceID;
        })
        .catch((err:any)=>{
            console.error("Error setting default Lead Source");
        });
        
    }

    loadHereMapScripts(){
        const fcn = (url:any, id:any) => {
            const elem = document.createElement("script");
            elem.async = false;
            elem.src = url;
            elem.id = id;
            document.head.appendChild(elem);
        }
        fcn('https://js.api.here.com/v3/3.1/mapsjs-core.js', 'mapsjs-core-js');
        fcn('https://js.api.here.com/v3/3.1/mapsjs-ui.js', 'mapsjs-ui-js');
    }

    initListeners(): any {
        this.firstName.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.firstName, false) });
        this.lastName.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.lastName, false) });
        this.email.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.email, false) });
        this.phoneNumber.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.phoneNumber, false) });
        this.address.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.address, false) });
        this.address.addEventListener('input', this.setAutoSuggested.bind(this));
        this.address2.addEventListener('input', (e) => { e.preventDefault(); handleInput(this.address2, false) });
        this.zipCode.addEventListener('input', (e) => { e.preventDefault(); this.counter = 0; handleInput(this.zipCode, false) });
        this.preferMethods.forEach((chkElem) => {
            chkElem.addEventListener('input', (e) => { e.preventDefault(); handleCheckboxInput(this.preferMethods) });
        });
        if (this.homeSqrft) {
            this.homeSqrft.addEventListener('input', (e) => { e.preventDefault(); validateSqrft() });
        }
        if (this.officeSqrft) {
            this.officeSqrft.addEventListener('input', (e) => { e.preventDefault(); validateSqrft() });
        }
        
    }
    private setAutoSuggested = async (e: any) => {

        e.preventDefault()
        let isFlag = false
        const input: any = document.querySelectorAll('.input-label-wrap #mly-user-zip-code')[0]
        sessionStorage.setItem("suggestion", "false");
        this.address.removeAttribute("suggestion");
        sessionStorage.setItem("manualCity", "");
        sessionStorage.setItem("manualState", "");
        if (input && input.id == 'mly-user-zip-code') {
            isFlag = true
        }

        const result = await discoveryAPI(`${(isFlag && this.zipCode.value) ? `${(document.getElementById('mly-user-address') as HTMLInputElement).value}, ${this.zipCode.value}` : (document.getElementById('mly-user-address') as HTMLInputElement).value}`);
        if (result?.items?.length > 0) {
            allAddressList = result?.items
            suggestedAddress = result?.items[0]
            this.setDatat(result?.items.map((item: any) => item))
        }

    }

    private setDatat = (suggestions: any) => {
        try {

            const inputElement: any = document.getElementById("mly-user-address") as HTMLInputElement;
            const inputValue = inputElement.value.toLowerCase();
            const suggestionContainer: any = document.createElement("ul") as HTMLElement
            suggestionContainer.className = "suggestion-container";

            // Clear previous suggestions
            while (inputElement.nextSibling && inputElement.nextSibling.className === "suggestion-container") {
                inputElement.parentNode.removeChild(inputElement.nextSibling);
            }

            if (inputValue) {

                suggestions.forEach((matchingSuggestion: any) => {
                    
                    const suggestionItem = document.createElement("li");
                    suggestionItem.className = "suggestion-item";
                    suggestionItem.textContent = matchingSuggestion?.title;
                    suggestionItem.setAttribute('data-city', matchingSuggestion?.address.city ?? '');
                    suggestionItem.setAttribute('data-country-name', matchingSuggestion?.address?.countryName ?? '');
                    suggestionItem.setAttribute('data-state-code', matchingSuggestion?.address?.stateCode ?? '');
                    suggestionItem.setAttribute('data-state-name', matchingSuggestion?.address?.state ?? '');

                    suggestionItem.addEventListener("click", () => {
                        
                        sessionStorage.setItem("suggestion", "true");
                        inputElement.value = matchingSuggestion?.title;
                        this.address.setAttribute("suggestion", "true");
                        suggestionContainer.parentNode.removeChild(suggestionContainer);
                        // this.setZipCode()
                        // concatinating address field with houseNumber + streetName to be passed in booking api //230654
                        this.hadleaddressReate(matchingSuggestion?.title);
                        const city = suggestionItem.getAttribute('data-city') ?? '';
                        const countryName = suggestionItem.getAttribute('data-country-name');
                        const stateCode = suggestionItem.getAttribute('data-state-code') ?? '';
                        const state = suggestionItem.getAttribute('data-state-name') ?? '';
                        this.bookingPayload.City = city;
                        this.bookingPayload.State = state;
                        handleInput(this.address, false);
                    });

                    suggestionContainer.appendChild(suggestionItem);
                });

                if (suggestions.length > 0) {
                    inputElement.parentNode.insertBefore(suggestionContainer, inputElement.nextSibling);
                }
            }

        } catch (error) {
            console.log("error", error)
        }

    }

    hadleaddressReate(matchingSuggestion: any) {
        address1Details = '';
        const matchedAddress = allAddressList?.find((address: any) => address?.title.trim() === matchingSuggestion.trim());
        sessionStorage.setItem("stateCode", matchedAddress?.address?.stateCode);
        sessionStorage.setItem("countryName", matchedAddress?.address?.countryName)
        if (matchedAddress) {
            if (matchedAddress?.address?.houseNumber && matchedAddress?.address?.street) {
                address1Details = matchedAddress?.address?.houseNumber + " " + matchedAddress?.address?.street;
            } else {
                let hasSteert: any = Object.keys(matchedAddress?.address).includes('street');
                let hasHousenum: any = Object.keys(matchedAddress?.address).includes('houseNumber');
                if (hasSteert === true && hasHousenum === false) {
                    address1Details = matchedAddress?.address?.street;
                }
                if (hasSteert === false && hasHousenum === true) {
                    address1Details = matchedAddress?.address?.houseNumber;
                }
            }
        }
    }

    preferredCommunicationMethod(): any {
        const preferMethods = this.preferMethods;
        let selectedPreferencesText: any = [];
        let selectedPreferences: any = [];
        const obj: any = {
            "Text": 2,
            "Phone": 1,
            "Email": 3
        }
        if (preferMethods) {
            preferMethods.forEach((el: any) => {
                if (el.type == "checkbox" && el.checked) {
                    const labelElement = document.querySelector(`[for="${el.id}"]`) as HTMLElement;
                    const label = labelElement.innerText;
                    selectedPreferencesText.push(label.trim());
                    selectedPreferences.push(obj[label.trim()]);
                }
            })
        }
        sessionStorage.setItem('PreferredCommunicationText', selectedPreferencesText.join(', '));
        return selectedPreferences.toString();
    }

    initLeadSources(): any {
        const refId = 'mly-how-about-us';
        const referenceDetails = document.getElementById(refId) as HTMLSelectElement;
        if (referenceDetails) {
            const optionsDiv = referenceDetails.parentElement?.querySelector('div.select-items');
            const selectBtn = referenceDetails.parentElement?.querySelector('button') as HTMLButtonElement;
            const btnList = document.createElement('button');
            if (optionsDiv) {
                optionsDiv.innerHTML = '';
                btnList.textContent = "Select a Referal Method";
                btnList.addEventListener("click", (e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    helperSelectFcn(selectBtn);
                    optionsDiv?.classList.add("select-hide");
                    selectBtn?.classList.remove("select-arrow-active");
                });
                optionsDiv.appendChild(btnList);
            }
            confirmMlyLeadSourceAPI()
                .then((endpoint) => {
                    let self = this;
                    getCachedAPIResponse(endpoint)
                        .then((data: any) => {
                            data = data?.filter((item: any) => item.leadSourceName !== "Undefined" && item.isActive);
                            data.forEach((element: any) => {
                                const d = document.createElement('button');
                                const val = element.leadSourceId ? element.leadSourceId : element.syncGenericLeadSourceId ? element.syncGenericLeadSourceId : "";
                                d.setAttribute('data-type-lead-source-id', val);
                                d.setAttribute('data-type-lead-source-name', element.leadSourceName);
                                d.textContent = element.leadSourceName;
                                d.addEventListener("click", (e: any) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    helperSelectFcn(selectBtn);
                                    optionsDiv?.classList.add("select-hide");
                                    if (selectBtn) {
                                        selectBtn.textContent = d.getAttribute('data-type-lead-source-name');
                                        selectBtn.value = d.getAttribute('data-type-lead-source-id') as string;
                                        selectBtn.classList.remove("select-arrow-active");
                                    }
                                    validateDropdown(refId);
                                });
                                optionsDiv?.appendChild(d);
                            });
                            selectBtn?.addEventListener("click", function (e: MouseEvent) {
                                e.stopPropagation();
                                self.closeAllSelect(this);
                                optionsDiv?.classList.toggle('select-hide');
                                selectBtn.classList.toggle('select-arrow-active');
                                if (!this.classList.contains("select-arrow-active") && selectBtn?.nextElementSibling) {
                                    (selectBtn.nextElementSibling as HTMLDivElement)?.classList.add("select-hide");
                                }
                                document.addEventListener('keydown', (event) => {
                                    if (event.key === 'Escape') {
                                        optionsDiv?.classList.add("select-hide");
                                        selectBtn.classList.remove("select-arrow-active");
                                    }
                                });
                                document.addEventListener('click', (event) => {
                                    optionsDiv?.classList.add("select-hide");
                                    selectBtn.classList.remove("select-arrow-active");
                                });

                                helperSelectFcn(selectBtn);
                                
                            });

                        })
                        .catch((error: any) => {
                            console.log('Could not get lead sources in CLEO', error);
                        });
                })
                .catch((err: any) => {
                    throw err;
                });

        }

    }
    getWeeklyFrequency(): string {
        const radioButtons = this.weeklyFreq;
        for (const radioButton of radioButtons) {
            if ((radioButton as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${radioButton.id}"]`) as HTMLElement;
                const label = labelElement.innerText;
                return label;
            }
        }
        return '';
    }
    getRadioBtnVal(id: string): string {
        const radioButtons = document.getElementsByName(id) as NodeListOf<Element>;
        for (const radioButton of radioButtons) {
            if ((radioButton as HTMLInputElement).checked) {
                const labelElement = document.querySelector(`[for="${radioButton.id}"]`) as HTMLElement;
                const label = labelElement.innerText;
                return label;
            }
        }
        return '';
    }

    closeAllSelect(except: ExceptType) {
        const allSelectItems: NodeListOf<HTMLDivElement> = document.querySelectorAll(".mly-lead-started-form .select-items.slim-scroll");
        const allSelectButtons: NodeListOf<HTMLButtonElement> = document.querySelectorAll(".mly-lead-started-form .select-selected");
        allSelectItems.forEach((item, index) => {
            if (except !== allSelectButtons[index]) {
                item.classList.add("select-hide");
                allSelectButtons[index]?.classList?.remove("select-arrow-active");
                if (allSelectButtons[index]?.nextElementSibling) {
                    allSelectButtons[index]?.nextElementSibling?.classList.add("select-hide");
                }
            }

        });


    }
    populateDropdowns(id: string) {
        const selectTag = document.getElementById(id) as HTMLSelectElement;
        if (selectTag) {
            const optionsDiv = selectTag.parentElement?.querySelector('div.select-items');
            const selectBtn = selectTag.parentElement?.querySelector('button') as HTMLButtonElement;
            if (optionsDiv) {
                optionsDiv.innerHTML = '';
            }
            const optionsArray = selectTag?.querySelectorAll("option") as any;
            let self = this;
            optionsArray.forEach((opt: any) => {
                const d = document.createElement('button');
                d.textContent = opt?.value;
                d.value = opt?.value;
                d.addEventListener("click", (e: any) => {
                    e.preventDefault();
                    e.stopPropagation();
                    if (selectBtn) {
                        selectBtn.textContent = d.textContent;
                        selectBtn.value = d.value;
                        selectTag.setAttribute('selected-value', d.value);
                        helperSelectFcn(selectBtn);
                        optionsDiv?.classList.add("select-hide");
                        selectBtn.classList.remove("select-arrow-active");
                        validateDropdown(id);
                    }
                });
                optionsDiv?.appendChild(d);
            });
            selectBtn?.addEventListener("click", function (e: MouseEvent) {
                e.preventDefault();
                e.stopPropagation();
                self.closeAllSelect(this);
                optionsDiv?.classList.toggle('select-hide');
                this.classList.toggle("select-arrow-active");

                if (!this.classList.contains("select-arrow-active")) {
                    if (selectBtn?.nextElementSibling) {
                        (selectBtn.nextElementSibling as HTMLDivElement)?.classList.add("select-hide");
                    }
                }

                document.addEventListener('keydown', (event) => {
                    if (event.key === 'Escape') {
                        optionsDiv?.classList.add("select-hide");
                        selectBtn.classList.remove("select-arrow-active");
                    }
                });
                document.addEventListener('click', (event) => {
                    optionsDiv?.classList.add("select-hide");
                    selectBtn.classList.remove("select-arrow-active");
                });

                helperSelectFcn(selectBtn);
            });
        }

    }

    readForm() {

        let finalAddress: any = ''
        let getsuggestionFlag = sessionStorage.getItem("suggestion");
        if (getsuggestionFlag === "true") {
            finalAddress = address1Details ? address1Details : "No address collected";
        } else {
            finalAddress = this.address.value;
        }
        this.bookingPayload.FirstName = this.firstName.value;
        this.bookingPayload.LastName = this.lastName.value;
        this.bookingPayload.Email = this.email.value;
        this.bookingPayload.Address = finalAddress;
        this.bookingPayload.Address2 = this.address2.value ? this.address2.value : '';
        this.bookingPayload.Phone = this.phoneNumber.value;
        this.bookingPayload.ZipCode = this.zipCode.value;
        this.bookingPayload.PostalCode = this.bookingPayload.ZipCode;
        sessionStorage.setItem('zipcode', this.bookingPayload.ZipCode);
        this.bookingPayload.PreferredCommunicationType = this.preferredCommunicationMethod();
        if (!this.leadSourceBtn?.textContent?.toLowerCase().includes("select")) {
            this.bookingPayload.LeadSource = this.leadSourceBtn.textContent as string;
            this.bookingPayload.LeadSourceId = Number(this.leadSourceBtn.value);
        }

    }
    readResidentialForm() {
        this.bookingPayload.CustomerType = "Residential";
        this.bookingPayload.EstimateBathrooms = this.resBathrooms.getAttribute('selected-value') as string;
        this.bookingPayload.EstimateBedrooms = this.bedrooms.getAttribute('selected-value') as string;
        this.bookingPayload.EstimateSquareFeet = this.homeSqrft.value;
        if (this.cleaningType.getCleaningFreq().includes('One-time')) {
            this.bookingPayload.JobFrequency = 'One-time';
            this.bookingPayload.JobFrequencyDetail = this.getRadioBtnVal('one-time-radio');
        }
        else {
            this.bookingPayload.JobFrequency = 'Recurring';
        }

    }
    readCommercialForm() {
        this.bookingPayload.CustomerType = "Commercial";
        this.bookingPayload.IsLeadOnly = true;
        this.bookingPayload.EstimateBathrooms = this.bathrooms.getAttribute('selected-value') as string;
        this.bookingPayload.EstimateBedrooms = this.offices.getAttribute('selected-value') as string;
        this.bookingPayload.EstimateSquareFeet = this.officeSqrft.value;
        if (this.cleaningType.getCleaningFreq().includes('Recurring')) {
            this.bookingPayload.JobFrequencyDetail = this.getWeeklyFrequency();
            this.bookingPayload.JobFrequency = 'Recurring';
        }
        else {
            this.bookingPayload.JobFrequencyDetail = "Move In/Out Clean:" + this.getRadioBtnVal('move-in-out-radio');
            this.bookingPayload.JobFrequency = 'One-time';
        }

    }
    setHeaderFooter(resultData: any) {
        sessionStorage.setItem('franchiseWebLocationId', resultData.franchiseWebLocationId);
        sessionStorage.setItem('franchiseId', resultData.franchiseId);
        sessionStorage.setItem('doingBusinessAs', resultData.doingBusinessAs);
        localStorage.setItem('weblocationId', resultData.franchiseWebLocationId);
    }
    openPropertyValidationModal(addressParam: string, suggestedParam: string) {
        stopLoader();
        const callPopupModal = document.getElementById('address-modal')
        callPopupModal?.click()
        const enteredAddr = document.querySelector('label[for="entered-address-radio"]');
        const suggestAddr = document.querySelector('label[for="suggested-address-radio"]');
        if (enteredAddr && suggestAddr) {
            enteredAddr.textContent = addressParam;
            suggestAddr.textContent = suggestedParam;
        }
        document.querySelector('.cta-wrapper .primary-btn')?.addEventListener("click", () => {
            let output: any;
            const suggestAddrInp = document.getElementById('suggested-address-radio') as HTMLInputElement;
            if (suggestAddrInp?.checked) {
                output = suggestAddr?.textContent;
                this.address.value = output;
                this.address.setAttribute("suggestion", "true");
            }
            else {
                output = enteredAddr?.textContent;
            }
            sessionStorage.setItem('sAddressParam', output);
            this.handleSubmit();
        });

    }


    openNearByLocationsModal(resultData: any) {
        stopLoader();
        const modal = document.getElementById("user-zip-code");
        if (modal) {
            if (!(document.getElementsByClassName('modalgrey').length)) {
                let bodycomponent = document.querySelector('body') as HTMLElement;
                bodycomponent.insertAdjacentHTML('beforeend', `<div modal-backdrop="" class="bg-gray-900 bg-opacity-50 dark:bg-opacity-80 fixed inset-0 z-40 modalgrey"></div>`)
            }
            const totalResults = resultData?.length;
            const zipCode = this.zipCode?.value;
            const resultsTextElement = document.querySelector('.results-text');
            if (resultsTextElement) {
                resultsTextElement.textContent = ` ${totalResults} Results for ${zipCode}`;
            }
            const ul = document.querySelector('.user-zip-result-list') as HTMLElement;
            ul.innerHTML = '';
            const sortedResultData = resultData?.slice().sort((a: any, b: any) => a.distance - b.distance);
            sortedResultData?.forEach((item: any) => {
                const liElement = document.createElement('li');
                liElement.className = 'user-zip-result-item';
                const distanceRounded = Math.round(item.distance) + ' mi.';
                liElement.innerHTML = `
                <div class="locaion-info">
                <div class="locaion-time">
                  <svg class="icon">
                    <use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#map-pin-primary"></use>
                  </svg>
                  <span class="location-text">${distanceRounded}</span>
                </div>
                <div class="locaion-name">
                  <p class="location-main">${item.doingBusinessAs}</p>
                  <a class="location-call" href="tel:${item.phoneNumber}">(${item.phoneNumber.slice(0, 3)}) ${item.phoneNumber.slice(3, 6)}-${item.phoneNumber.slice(6)}</a>
                </div>
              </div>
              <div class="locaion-btn">
                <a href="#" class="btn primary-btn">
                  REQUEST ESTIMATE
                  <svg><use xlink:href="/brand/_assets/images/icons/hero-icon-sprite.svg#chevron-right-white"></use></svg>
                </a>
              </div>
                `;
                ul?.appendChild(liElement);

            });
            modal.style.display = 'flex'
            modal.classList.remove('hidden');
            const closeLocalModal = document.querySelector('.user-zip-close-modal');
            closeLocalModal?.addEventListener('click', function handleClick(event) {
                modal?.classList.add("hidden");
                modal.style.display = 'none';
                (document.querySelector(".modalgrey") as HTMLElement)?.remove();
            })
            const primaryButton = document.querySelectorAll<HTMLAnchorElement>('.locaion-btn .btn.primary-btn');
            let self = this;
            primaryButton.forEach(button => {
                const bookingData = self.bookingPayload;
                let matchingData :any;
                button.addEventListener('click', function (event: MouseEvent) {
                    event.preventDefault();
                    const anchor = this;
                    const listItem = anchor.closest('li.user-zip-result-item');
                    // Check if listItem exists and is valid
                    if (listItem) {
                        const locationMain = listItem.querySelector<HTMLParagraphElement>('.location-main')?.textContent;
                        matchingData = resultData.find((data: any) => data.doingBusinessAs === locationMain);
                        bookingData.City = matchingData?.city;
                        bookingData.State = matchingData?.state;
                        bookingData.WebLocationId = Number(matchingData?.franchiseWebLocationId);
                        self.formSubmissionLeadFlow(bookingData);
                    }
                });

            });

        }
    }
    async handleSubmit() {
        const form = document.querySelector('#form-section .mly-lead-started-form .contact-us-section');
        if (validateForm(form)) {
            startLoader();
            this.readForm();
            const addrFlag = sessionStorage.getItem("suggestion");
            if(addrFlag === "false"){
                // call discovery API to get city and state info
                discoveryAPI(this.zipCode.value)
                .then((result: any) => {
                    if (result) {
                        const outputData = result.items[0];
                        const { Address, Address2, City, State, ZipCode } = this.bookingPayload;
                        const addressParts = [
                            Address,
                            Address2 ? `, ${Address2}` : '',
                            City ? `, ${City}` : '',
                            State ? `, ${State}` : '',
                            ZipCode ? ` ${ZipCode}` : ''
                        ];
                        const addressParam = addressParts.join('');
                        sessionStorage.setItem('sAddressParam', addressParam);
                        this.bookingPayload.City = outputData.address.city;
                        this.bookingPayload.State = outputData.address.stateCode;
                        this.callLookupAPI();
                    }
                    else {
                        throw Error('Heremap API does not return');
                    }
                })
                .catch((err: any) => {
                    throw err;
                });
            }
            else{
                // call lookup API
                this.callLookupAPI();
            }
            
        }
    }
    callLookupAPI(){
        let addressParam: string;
        this.counter++;
        if (this.counter > 1) {
            addressParam = this.address.value.concat(" ",this.address2.value, " ", this.bookingPayload.City , " ", this.bookingPayload.State, " ", this.bookingPayload.PostalCode);
        }
        else {
            addressParam = this.bookingPayload.PostalCode;
        }
        getFranchiseDetails(addressParam).then((result) => {
            if (result?.length > 0) {
                let self = this;
                localizationHelper(result, self);
                // Location found - > Check whether address entered manual or suggested
                // If manual, open modal and wait for response
                const addrFlag = sessionStorage.getItem("suggestion");
                if(addrFlag === "false"){
                    // If manual address
                    if(this.counter<=1){
                        // First instance
                        this.openPropertyValidationModal(addressParam, suggestedAddress?.address?.label);
                    }
                    else{
                        this.decideFlow();
                    }
                    
                }
                else{
                    // if suggested address
                    this.decideFlow();
                }
                
            }
            else {
                this.handleNearbyLocationsFlow();
            }
        })
        .catch(async (err: any) => {
                stopLoader();
                let addressParam = this.address.value.concat(" ",this.address2.value, " ", this.bookingPayload.City , " ", this.bookingPayload.State, " ", this.bookingPayload.PostalCode);
                if (err instanceof Error && err.message.includes('Full address is needed to narrow down results')) {
                    if (this.counter > 1) {
                        this.handleNearbyLocationsFlow();
                    }
                    else {
                        this.openPropertyValidationModal(addressParam, suggestedAddress?.address?.label);
                    }

                }
                else{
                    console.error("API facing issues:", err.message);
                }
                
        });
    }
    decideFlow(){
        const cleanType = this.cleaningType.getCleaningType().toLowerCase();
        const cleanFreq = this.cleaningType.getCleaningFreq().toLowerCase();
        sessionStorage.setItem('JobType', cleanFreq);
        if (cleanType.includes('commercial')) {
            // Commercial is always Lead flow, regardless of OTC or recurring
            this.readCommercialForm();
            callBookingApi(this.bookingPayload);
        }
        else if (cleanType.includes('residential')) {
            this.readResidentialForm();
            determineFlowType(this.bookingPayload.WebLocationId)
                .then(async (resp: any) => {
                    this.bookingPayload.IsLeadOnly = !resp?.onlineScheduling;
                    sessionStorage.removeItem("mollyEstimate");
                    if (!cleanFreq.includes('recurring')) {
                        this.bookingPayload.IsLeadOnly = !resp?.options?.optInForPOSScheduling2;
                        await calcEstimate(this.bookingPayload);
                    }

                    if (this.bookingPayload.IsLeadOnly) {
                        callBookingApi(this.bookingPayload);
                    } else {
                        calendarFlowMLY(this.bookingPayload);
                    }
                })
                .catch((error: any) => {
                    throw error;
                });

        }
    }
    handleNearbyLocationsFlow() {
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        getBrandDetailsFromBrandJson(conceptCode)
            .then((brandData) => {
                if (brandData?.enable_50mile_area_OSflow) {
                    this.nearByLocations();
                }
                else {
                    console.log("Areas served functionality not enabled");
                    stopLoader();
                }
            })
            .catch((err) => {
                console.error("Error getting brands.json")
            });
    }

    nearByLocations() {
        const conceptCode = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
        let franchiseLimit, units, nearbyLocations;
        getBrandDetailsFromBrandJson(conceptCode)
            .then((brandDetail) => {
                franchiseLimit = Number(brandDetail.nearest_franchiselimit_OSflow);
                units = this.findUnits(brandDetail.area_measure_values_OSflow);
                nearbyLocations = new NearbyLocations(units, franchiseLimit);
                this.searchLocationsFlow(nearbyLocations);
            })
            .catch((err: any) => {
                console.log("Unable to read brands.json. Using default range 50 miles radius...");
                franchiseLimit = 50;
                units = "miles";
                nearbyLocations = new NearbyLocations(units, franchiseLimit);
                this.searchLocationsFlow(nearbyLocations);
            });
    }

    searchLocationsFlow(nearbyLocations: any) {
        nearbyLocations.searchNearbyLocations(this.zipCode.value)
            .then((data: any) => {
                stopLoader();
                // Handle the resolved data here
                const callPopupModal = document.getElementById("user-zip-code");
                if (callPopupModal && data?.length > 1) {
                    this.openNearByLocationsModal(data);
                }
                else if (data?.length == 1) {
                    this.setHeaderFooter(data[0]);
                    this.bookingPayload.City = data[0]?.city;
                    this.bookingPayload.State = data[0]?.state;
                    this.bookingPayload.WebLocationId = Number(data[0]?.franchiseWebLocationId);
                    this.formSubmissionLeadFlow(this.bookingPayload);
                }
                else if (data == null || data.length == 0) {
                    const errorMessage = `We're sorry. We don't currently provide service to ${this.zipCode.value}.`;
                    document.getElementById('error-modal-id')?.click();
                    document.getElementById('error-modal')?.classList.remove('hidden');
                    const errorElement = document.getElementById('modal-zipcode-error') as HTMLInputElement | null;
                    if (errorElement) {
                        errorElement.textContent = errorMessage;
                    }
                    document.getElementById('modal-zipcode-error')?.classList.remove('hidden');
                }
            }).catch((error: any) => {
                // Handle any errors here
                console.error('Error searching nearby locations:', error);
                stopLoader();
            });
    }

    updateInputValue(inputId: string, value: string | number | null | undefined) {
        try {
            const hiddenInput = document.getElementById(inputId) as HTMLInputElement | null;

            if (hiddenInput && value !== null && value !== undefined) {
                hiddenInput.value = value.toString();
            }
        } catch (error) {
            console.error(`Error in updateInputValue function: ${error}`);
        }
    }

    findUnits(area_measure_values: string): string {
        if (area_measure_values.includes('measure_km')) {
            return "km";
        }
        return "miles";
    }

    formSubmissionLeadFlow(bookingData:any) : any{
        const cleanType = this.cleaningType.getCleaningType().toLowerCase();
        if (cleanType.includes('commercial')) {
            this.readCommercialForm();
        }
        else{
            this.readResidentialForm();
        }
        bookingData.IsLeadOnly = true;
        callBookingApi(bookingData);
    }

}

// Initialize the form handler when the DOM is ready for MLY
document.addEventListener("DOMContentLoaded", () => {
    const conceptCode: any = (document.getElementById('conceptCode') as HTMLInputElement)?.value;
    const divTag: any = document.getElementsByClassName('mly-lead-started-form');
    if (conceptCode === "MLY" && divTag?.length > 0) {
        const flow = new MollyFlow();
        togglePreferredContactInConfirmationPage();
        let progressBar = document.querySelector("#progress") as HTMLElement;
        progressBar.style.width = "0%";
        let bars = document.querySelectorAll('.progress-step');
        if (bars[1].className.includes("prograssbar-length progress-step-active")) {
            bars[1].classList.remove("prograssbar-length");
            bars[1].classList.remove("progress-step-active");
        }
        flow.cleaningType.toggleCleaningDiv();
        flow.cleaningType.cleaningType.forEach((element) => {
            element?.addEventListener("click", () => { flow.cleaningType.toggleCleaningDiv(); });
        });
        flow.cleaningType.cleaningFreq.forEach((element) => {
            element?.addEventListener("click", () => { flow.cleaningType.toggleCleaningDiv(); });
        });
        const resOTelems = document.querySelectorAll('input[name*="one-time-radio"]');
        const commOTelems = document.querySelectorAll('input[name*="move-in-out-radio"]');
        resOTelems.forEach((element) => {
            element?.addEventListener("click", () => { flow.cleaningType.toggleCleaningDiv(); });
        });
        commOTelems.forEach((element) => {
            element?.addEventListener("click", () => { flow.cleaningType.toggleCleaningDiv(); });
        });
        const nextBtns = document.querySelectorAll('#form-section .mly-lead-started-form .btn-next');
        nextBtns.forEach((btn) => {
            btn?.addEventListener("click", (e: any) => {
                flow.handleSubmit();
            });
        });
        /*window.onbeforeunload = async function ()
        {
            flow.handleSubmit();
        }*/
    }
});

function localizationHelper(result:any, self:MollyFlow){
    const { franchiseWebLocationId, doingBusinessAs, franchiseId, phoneNumber } = result[0];
            
    // Localization related code
    sessionStorage.setItem('franchiseWebLocationId', franchiseWebLocationId);
    sessionStorage.setItem('franchiseId', franchiseId);
    sessionStorage.setItem('doingBusinessAs', doingBusinessAs);
    localStorage.setItem('weblocationId', franchiseWebLocationId);

    const locationUrl = result[0].locationWebsiteUrl?.replace(/\/$/, '') ?? '';
    const splitUrlArr = locationUrl.toLowerCase().split("/");
    const pathname = splitUrlArr[splitUrlArr.length - 1];
    if (pathname) {
        localStorage.setItem('dbaName', pathname);
    }

    self.updateInputValue("local_weblocationId", franchiseWebLocationId);
    self.updateInputValue("weblocationId", franchiseWebLocationId);
    self.updateInputValue("full_dbaName", pathname);
    self.updateInputValue("dbaName", pathname);

    const address = document.querySelector('.header-wrapper .address')
    const desc = document.querySelector('.header-wrapper .description')
    const phone = document.querySelector('.header-wrapper .form-call-cta') as HTMLAnchorElement;

    if (pathname && doingBusinessAs) {
        updateHeaderContent(doingBusinessAs);
    }

    if (address && desc && phone) {
        address.innerHTML = doingBusinessAs
        desc.innerHTML = 'Locally Owned and Operated'
        phone.href = `tel:${phoneNumber}`;
        phone.innerText = `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    }
    //const addressEntered: any = (document.getElementById('mly-user-address') as HTMLInputElement).value;
    //sessionStorage.setItem("manualEnteredAddress", addressEntered + ", " + self.zipCode?.value);
    sessionStorage.setItem("RL_Weblocation_ID", franchiseWebLocationId);
    sessionStorage.setItem("RL_DBAName", doingBusinessAs);
    self.bookingPayload.WebLocationId = Number(franchiseWebLocationId);
    
    self.bookingPayload.FranchiseId = franchiseId;
    self.bookingPayload.IsLocalized = self.bookingPayload.WebLocationId ? true : false;
            
}

function togglePreferredContactInConfirmationPage() {
    const tag = document.getElementById("mly_hide_preferred_contact");
    if (tag) {
        sessionStorage.setItem("hidePreferMethod", "true");
    } else {
        sessionStorage.setItem("hidePreferMethod", "false");
    }
}

