import axios from "axios";
import apiRequest from "../api/apiRequest";
import { apiConfig } from "../config/apiConfig";
import { REGEX } from "../constants/regex";
import { StateHandler } from "../util/getStateFun";
import { startLoader, stopLoader } from "../util/loader"
import { LocateAddressBumper } from "../header/locateAddressBumper";
import { getCountryCode } from "../location-search-map/getCountryCode";

export class MrElectricFormHandler {
  private form: HTMLFormElement;
  private findSection: HTMLElement;
  private zipCodeInput: HTMLInputElement;
  constructor() {
    this.form = document.querySelector(".find-input-wrapper button") as HTMLFormElement;
    this.findSection = document.querySelector(".find-section") as HTMLElement;
    this.zipCodeInput = document.getElementById('find-local-input') as HTMLInputElement;

    if (localStorage.getItem('weblocationId') && this.findSection) {
      this.findSection.style.display = "none"; // user when local page ready
    }
    // Attach event listener to the form submission
    if (this.form) this.form.addEventListener("click", this.handleSubmit.bind(this));
    // let errorMessageSpan = document.createElement('span');
    // errorMessageSpan.id = 'modal-error_message';
    // errorMessageSpan.className = 'error-msg block';
    // errorMessageSpan.style.display = 'none';

    const inputZIpCode = document.getElementById('find-local-input') as HTMLInputElement;
    if(inputZIpCode){

    // inputZIpCode?.setAttribute('required', 'true')
    // inputZIpCode?.setAttribute('maxlength', '10')
    // inputZIpCode?.setAttribute('pattern', `${REGEX.sendZip}`)
    // inputZIpCode?.setAttribute('title', 'Enter a valid zip code')

    // inputZIpCode?.setCustomValidity('Enter a valid zip code');

    // inputZIpCode?.addEventListener('input', () => {
    //   const reg = REGEX.sendZip;
    //   const val = inputZIpCode?.value;

      // if (val === '') {
      //   errorMessageSpan.style.display = 'none'; // Hide the error message
      // } 
      // else 
      // if (val.includes('00000')) {
      //   inputZIpCode?.setCustomValidity('Invalid ZIP/Postal Code');
      //   errorMessageSpan.textContent = 'Invalid ZIP/Postal Code'; // Set the error message text
      //   errorMessageSpan.style.display = 'block'; // Show the error message
      // } 
      // else if (reg.test(val)) {
      // inputZIpCode.removeAttribute('pattern')
      // inputZIpCode.setCustomValidity('');
      // errorMessageSpan.style.display = 'none'; // Hide the error message
      // }
      // else
      // {
      //   inputZIpCode.setCustomValidity('Enter a valid zip code');
      //   inputZIpCode?.setAttribute('pattern', `${REGEX.sendZip}`)
      //   errorMessageSpan.textContent = 'Invalid ZIP/Postal Code'; // Set the error message text
      //   errorMessageSpan.style.display = 'block'; // Show the error message
      // }
      // const formCtaDiv = document.querySelector(".cta-wrapper .call-cta-wrapper");
      //   if (formCtaDiv && formCtaDiv.parentNode) {
      //     // Insert the <span> element before the "form-cta" div
      //     formCtaDiv.parentNode.insertBefore(errorMessageSpan, formCtaDiv);
      //   }
      //   inputZIpCode.addEventListener('blur', () => {
      //     if (inputZIpCode.value === '') {
      //         errorMessageSpan.style.display = 'none';
      //     }
      // });

      // if(val && val.length == 1){
      //   if(this.form){
      //     this.form.submit()
      //   }
      // }
  
    // })

    this.zipCodeInput?.addEventListener('input', this.zipCodeError.bind(this))


  }

  }


  zipCodeError() {
    if (this.zipCodeInput.value != '' && !REGEX.sendZip.test(this.zipCodeInput.value.trim())) {

        const fieldError: any = document.getElementById(`find-local-input-error-msg`);
         fieldError.classList.remove('hidden')
       //fieldError.innerHTML = 'Invalid zip code format.' 

       !fieldError.innerHTML.trim() && (fieldError.innerHTML = 'Invalid zip code format.' )
  

    }
    else {
      const fieldError: any = document.getElementById(`find-local-input-error-msg`);
      fieldError.classList.add('hidden')
    
  
    }

}
  

  handleSubmit = (event: Event) => {
    event.preventDefault();
    event.stopPropagation();
    const zipCodeInput = document.getElementById("find-local-input") as HTMLInputElement;
    const zipCode = zipCodeInput.value.trim();
    if (this.validateForm()) {
      startLoader()
      this.getData(zipCode);
    }
  }

 
  getData(zipCode: string) {
    const submitButton = document.querySelector(".find-input-wrapper button") as HTMLButtonElement;
    submitButton.setAttribute("disabled", "true");

    axios.get(apiConfig.LocateLocationApiWithRoundRobinFalse.replace("sAddressParamValue", encodeURIComponent(zipCode)), {
      headers: {
        'Cache-Control': 'max-age=0',
      },
    })
    .then((response) => {
        submitButton.disabled = false;
        const result = response.data;

        if (result.length > 0) {
          sessionStorage.setItem('alternateConcept', result[0].alternateConcept);
          sessionStorage.setItem('franchiseWebLocationId', result[0].franchiseWebLocationId);
          let countryCode = getCountryCode();
          sessionStorage.setItem('countryCode', countryCode);
          sessionStorage.setItem('doingBusinessAs', result[0].doingBusinessAs);
          sessionStorage.setItem('franchiseeName', result[0].franchiseeName);
          sessionStorage.setItem('sAddressParam', zipCode)  

          localStorage.setItem("alternateConcept", result[0].alternateConcept);
          localStorage.setItem("franchiseWebLocationId", result[0].franchiseWebLocationId);
          this.findSection.style.display = "none";
          const sRedirectURL = result[0].locationWebsiteUrl ?? "";
          const hostURL = new URL(sRedirectURL);
          const hostname = hostURL.host;
          const pathname = hostURL.pathname;         
          const redirectURL = new URL(window.location.href);
          const redirectHostName = redirectURL.host
          window.location.replace(`https://${redirectHostName}${pathname}`);
        } else {
          const submitButton = document.querySelector(".find-local") as HTMLButtonElement;
          const input = document.getElementById("local-search-modal") as HTMLInputElement;
          input.value = zipCode;

          const text1 = document.getElementById("step1-text1") as HTMLElement;
          const text2 = document.getElementById("step1-text2") as HTMLElement;
          text1.style.display = "none";
          text2.style.display = "block";

          submitButton.click();

        }
        stopLoader()
      })
      .catch((error) => {
        stopLoader();
        submitButton.disabled = false;
        const duplicate = error.response.data;
        const headerLocalBtn = document.querySelector(".brand-header .find-local") as HTMLElement;
        if (duplicate.Message === 'Full address is needed to narrow down results') {
          const stateHandler = new StateHandler();
          stateHandler.getStateFun(); 
          const modalWindow = document.getElementById("glb-find-local");
          const step1 = document.querySelector('.step-1') as HTMLElement;
          const step2 = document.querySelector('.step-2') as HTMLElement;

          if (step1) {
            step1.classList.add('hidden');
          }

          if (step2) {
            step2.classList.remove('hidden');
            step2.classList.add('block');

            const closeLocalModal = document.querySelector('.close-modal-btn');
            closeLocalModal?.addEventListener('click', function handleClick(event) {

            step2.classList.add("hidden");
            step1.classList.remove("hidden");
            })
          }
          headerLocalBtn?.click();
          const modalZipCode = document.querySelector('.step-2 #modal-zipcode') as HTMLInputElement;
          modalZipCode.value = encodeURIComponent(zipCode);

          const locateAddressBumper = new LocateAddressBumper();
        }
      });
  }

  validateForm(): boolean {
    const zipCodeInput = document.getElementById("find-local-input") as HTMLInputElement;
    const zipCode = zipCodeInput.value;
    const formFields = { zipCode };
    const errors: any = {};

 
    if (zipCodeInput.required && formFields.zipCode === '') {
      errors['find-local-input'] = '';
  } else if (zipCodeInput.required && !REGEX.sendZip.test(formFields.zipCode.trim())) {
      errors['find-local-input'] = 'Invalid zip code format.';
  }

   
    document.querySelectorAll('.error-msg').forEach(e => e.classList.add('hidden'));
    // const fieldmain: any = document.querySelector('form . find-input-wrapper');


    Object.keys(errors).forEach((fieldId, index) => {
      const field: any = document.getElementById(fieldId);
      const fieldError: any = document.getElementById(`${fieldId}-error-msg`);
      if (field && field.parentNode) {
          const errorMessage = errors[fieldId];
          const errorElement = document.createElement('span');
          errorElement.className = 'error-msg';
          errorElement.textContent = errorMessage
          field.classList.add("invalid-field");
          if (fieldError && errorMessage) {
              fieldError.innerHTML = errorMessage
          }
          if (fieldError && fieldError.classList.contains('hidden'))
              fieldError.classList.remove('hidden')
          // field.parentNode.appendChild(errorElement);
      }
      if (index == 0 && field) {
          field.focus()
      }
  });

    return Object.keys(errors).length === 0;
  }
}

// Initialize the class when the DOM content is loaded
document.addEventListener("DOMContentLoaded", () => {
  new MrElectricFormHandler();
});
